import React from 'react';
import { Modal } from 'antd';
import { CrownWhite } from '../icons';
import './style.css';
import { Button, SecondaryButton } from 'src/pages/application/Login';

export const PremiumActivatedModal: React.FC<{ 
  open: boolean, 
  onExplore: () => void, 
  onClose: () => void 
}> = (props) => {
  return (
    <Modal 
      open={props.open} 
      onCancel={props.onClose}
      width={450} 
      centered 
      footer={null} 
      maskClosable={true}
      closeIcon={null}
    >
      <div className="premium-activated-modal">
        <div className="premium-activated-modal__header">
          <div className="premium-activated-modal__crown">
            <CrownWhite />
          </div>
          <h2 className="premium-activated-modal__title">You're Premium now</h2>
        </div>
        
        <p className="premium-activated-modal__message">
          Your subscription is now activated. Enjoy!
        </p>
        
        <div className="premium-activated-modal__actions">
          <Button 
            // className="primary modal-action-button" 
            onClick={props.onExplore}
          >
            Explore Premium
          </Button>
          <SecondaryButton 
            onClick={props.onClose}
          >
            OK
          </SecondaryButton>
        </div>
      </div>
    </Modal>
  );
};

export default PremiumActivatedModal; 