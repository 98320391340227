import { CrownWhite } from 'src/elements/icons';
import { Modal } from 'antd';
import { useState } from 'react';
import { usePrivyEmail } from 'src/hooks/usePrivyEmail';
import { useEnrolling } from 'src/hooks/useEnrolling';
import { EmailInput } from 'src/elements/emailInput';
import { sendSubscribeRequest } from 'src/requests';
import { validateEmail } from 'src/utils';
import './premium_modal_styles.css';
import useMe from 'src/hooks/useMe';
import { useChangingState } from 'src/hooks/useChangingState';
import { Button, Input } from '../../Login';

export const PremiumModal: React.FC<{ open: boolean, onOk: () => void, onCancel: () => void }> = (props) => {
  const {user} = useMe();
  const [name, setName] = useChangingState(user?.name);
  const {email, hasEmail, setEmail} = usePrivyEmail();
  const [emailChange, setEmailChange] = useState(false);
  const [nameError, setNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const {enroll} = useEnrolling();

  return <Modal open={props.open} onOk={props.onOk} onCancel={props.onCancel} width={450} centered footer={null} maskClosable={false}>
      <div className="premium-modal">
          <div className="premium-modal__header2">
              <div className="premium-modal__crown">
                  <CrownWhite/>
              </div>
              <h2 className="premium-modal__title2">Subscribe</h2>
          </div>
          <p className="premium-modal__description2">
              Thank you for choosing a premium plan! Please provide your email address below, and we'll send you the payment details.
          </p>
          <div className="premium-modal__form">
              <Input
                  type="text"
                  placeholder="Name"
                  className="premium-modal__input"
                  style={nameError ? {borderColor: '#ff4d4f'} : {}}
                  value={name}
                  onChange={(e) => {
                      setName(e.target.value);
                      if(nameError && e.target.value.trim()) setNameError('');
                  }}
              />
              {nameError && <div className="premium-modal__error-message">{nameError}</div>}
              <EmailInput
                  email={email}
                  setEmail={setEmail}
                  emailChange={emailChange}
                  setEmailChange={setEmailChange}
                  hasEmail={hasEmail}
              />
              {emailError && <div className="premium-modal__error-message">{emailError}</div>}
              <div className="premium-modal__actions2">
                  <Button className="primary modal-action-button modal-action-button2" onClick={() => {
                      let hasError = false;
                      if(!name.trim()) {
                          setNameError('Please enter your name');
                          hasError = true;
                      }
                      if(!validateEmail(email)) {
                          setEmailError('Please enter a valid email');
                          hasError = true;
                      }
                      if(hasError) return;
                      
                      props.onOk();
                      sendSubscribeRequest(false);
                      enroll(false);
                  }}
                          style={{display: 'block', width: '100%'}}
                  >
                      Send a request
                  </Button>
              </div>
          </div>
      </div>
  </Modal>
}