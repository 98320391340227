import { createContext, useContext, useState, ReactNode } from 'react';
import { Placement } from '@popperjs/core';
import { logEvent } from 'src/utils';
import { useMe } from './useMe';
interface OnboardingContextType {
  currentStep: number | null;
  setCurrentStep: (stepId: number | null) => void;
  getCurrentStepInfo: () => OnboardingStep | null;
  getTotalSteps: () => number;
  incrementStep: () => void;
  startOnboarding: () => void;
  skipOnboarding: () => void;
}

export const INVISIBLE_ID = -1;
const DEFAULT_ID = -20;

interface OnboardingStep {
  title: string;
  description: string;
  arrowPlacement: Placement;
}

const ONBOARDING_STEPS: Record<number, OnboardingStep> = {
  1: {
    title: "Deep scan",
    description: "Detailed scan with text segmentation by sentences",
    arrowPlacement: 'top'
  },
  2: {
    title: "Get certificate", 
    description: "Get detailed scan report",
    arrowPlacement: 'top'
  },
  3: {
    title: "Scan history",
    description: "Scroll down to see your scan history",
    arrowPlacement: 'top-start'
  },
  4: {
    title: "Britishize text",
    description: "Make your text looks natural for UK",
    arrowPlacement: 'right-start'
  },
  5: {
    title: "Statistics",
    description: "Track the usage of Scan and Britishizer",
    arrowPlacement: 'right-start'
  },
  6: {
    title: "API keys",
    description: "Manage API keys: create, delete, track analytics",
    arrowPlacement: 'right-start'
  }
};

const OnboardingContext = createContext<OnboardingContextType | undefined>(undefined);

export const OnboardingProvider = ({ children }: { children: ReactNode }) => {
  const [currentStep, setCurrentStep] = useState<number | null>(DEFAULT_ID);
  const {user, completeOnboarding} = useMe();

  const getCurrentStepInfo = () => {
    if (!currentStep) return null;
    return ONBOARDING_STEPS[currentStep] || null;
  };

  const getTotalSteps = () => {
    return Object.keys(ONBOARDING_STEPS).length;
  };

  const incrementStep = async () => {
    if (!currentStep) return;
    const stepNumber = currentStep;
    logEvent("onboarding__step_viewed", { step: stepNumber });
    setCurrentStep(stepNumber + 1);
    if (stepNumber + 1 === getTotalSteps()) {
      await completeOnboarding();
    }
  };

  const startOnboarding = () => {
    if (user?.onboarding_completed) {
      setCurrentStep(DEFAULT_ID);
      return;
    }
    setCurrentStep(1);
  };

  const skipOnboarding = async () => {
    setCurrentStep(DEFAULT_ID);
    await completeOnboarding();
  };

  return (
    <OnboardingContext.Provider value={{ currentStep, setCurrentStep, getCurrentStepInfo, getTotalSteps, incrementStep, startOnboarding, skipOnboarding }}>
      {children}
    </OnboardingContext.Provider>
  );
};

export const useOnboarding = () => {
  const context = useContext(OnboardingContext);
  if (!context) {
    throw new Error('useOnboarding must be used within a OnboardingProvider');
  }
  return context;
};
