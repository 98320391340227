import React from 'react';

export const ScanContainer: React.FC = () => {
  return <div className='scan__container'>
      <div className="scan-container__content">
          <div className="s-circle human-written"></div>
          <span>Human-written</span>
      </div>
      <div className="scan-container__content">
          <div className="s-circle mixed"></div>
          <span>Mixed</span>
      </div>
      <div className="scan-container__content">
          <div className="s-circle ai-gen"></div>
          <span>AI-generated</span>
      </div>
  </div>
}