import { useState } from "react";
import './style.css';
import { Input } from "src/pages/application/Login";

export const EmailInput: React.FC<{
  email: string;
  setEmail: (value: string) => void;
  emailChange: boolean;
  setEmailChange: (value: boolean) => void;
  hasEmail: boolean;
}> = ({email, setEmail, emailChange, setEmailChange, hasEmail}) => {
  const [isEmailChanged, setIsEmailChanged] = useState(!hasEmail);
  return (
      <div className="premium-modal__email-container">
          <Input
              type="email"
              className="disabled"
              placeholder={"Email"}
              value={email}
              onChange={(e) => {
                  setEmail(e.target.value);
              }}
              style={{marginBottom: !isEmailChanged ? "0px" : "10px"}}
              disabled={!isEmailChanged}
          />
          {
              !isEmailChanged ?
                  <p className="premium-modal__email-note" style={{marginBottom: "10px"}}>
                      Is it your current e-mail? <a href="#" className="premium-modal__link" onClick={() => {
                      setIsEmailChanged(true);
                  }}>Use different e-mail</a>
                  </p> : <></>
          }
      </div>
  );
};