
import { Modal } from 'antd';
import { logEvent } from '../../utils';
import { CrownWhite } from '../icons';
import './style.css';
import { Button, SecondaryButton } from 'src/pages/application/Login';


export const FreePremiumForm: React.FC<{ open: boolean, onOk: () => void, onCancel: () => void }> = (props) => {

  return <Modal open={props.open} onOk={props.onOk} onCancel={() => {
      props.onCancel()
      logEvent("free_premium__close");
  }} width={450} centered footer={null} maskClosable={false}>
      <div className="premium-modal">
          <div className="premium-modal__header">
              <div className="premium-modal__crown">
                  <CrownWhite/>
              </div>
              <h2 className="premium-modal__title">Get free Premium</h2>
          </div>
          <p className="premium-modal__description">
            Dear user, we are glad to see that you're interested in our AI detector. While currently, It's AI is only a startup, and we still have a lot to accomplish, it's very important for us to be aligned with our clients and to deeply understand their needs.
          </p>
          <p className="premium-modal__offer">
            So, we'll <strong>gift you a one-month premium subscription</strong> if you answer a few questions in the survey. It will take only 3-5 minutes and would be really helpful for us.
          </p>
          <div className="premium-modal__form">
              <div className="premium-modal__actions">
                  <SecondaryButton className="secondary modal-action-button" onClick={() => {
                      logEvent('free_premium__later');
                      props.onCancel();
                  }
                  }>
                      Remind me later
                  </SecondaryButton>
                  <Button className="primary modal-action-button" onClick={() => {
                      props.onOk();
                  }}>
                      Let's do it
                  </Button>
              </div>
          </div>
      </div>
  </Modal>
}