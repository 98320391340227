import { useEffect, useState } from "react";
import { usePrivy } from "@privy-io/react-auth";

export const usePrivyEmail = () => {
  const {user, ready} = usePrivy();
  const [email, setEmail] = useState('');
  const [hasEmail, setHasEmail] = useState(true);

  useEffect(() => {
      const fetchEmail = async () => {
          if (ready && user) {
              let userEmail = user.email?.address || '';
              if (!userEmail && user.google) {
                  userEmail = user.google.email || '';
              }
              if (userEmail) {
                  setEmail(userEmail);
                  setHasEmail(true);
              } else {
                  setHasEmail(false);
              }
          }
      };

      fetchEmail();
  }, [ready, user]);
  return {email, setEmail, hasEmail, setHasEmail};
};