import { useState, useEffect } from 'react';
import { Modal } from 'antd';
import { logEvent } from '../../utils';
import { CrownWhite, InfoIcon } from '../icons';
import './style.css';
import { sendQuestionnaireRequest } from 'src/requests';
import { Button, Input } from 'src/pages/application/Login';

// First form - Work information
export const WorkInfoForm: React.FC<{ 
  open: boolean, 
  onComplete: (workPlace: string, companyName: string, jobPosition: string, jobTitle: string) => void, 
  onCancel: () => void 
}> = (props) => {
  const [workPlace, setWorkPlace] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [jobPosition, setJobPosition] = useState('');
  const [jobTitle, setJobTitle] = useState('');

  const [formError, setFormError] = useState('');
  const [attemptedSubmit, setAttemptedSubmit] = useState(false);

  useEffect(() => {
    if (workPlace.trim() && companyName.trim() && jobPosition.trim() && jobTitle.trim()) {
      setFormError('');
    }
  }, [workPlace, companyName, jobPosition, jobTitle]);

  const hasMinimumInput = () => {
    return Boolean(workPlace.trim() && companyName.trim() && jobPosition.trim() && jobTitle.trim());
  };

  return (
    <Modal
      open={props.open}
      onCancel={() => {
        props.onCancel();
        logEvent('questionnaire_part1__close');
      }}
      width={450}
      centered
      footer={null}
      maskClosable={false}
    >
      <div className="premium-modal">
        <div className="premium-modal__header">
          <div className="premium-modal__crown">
            <CrownWhite />
          </div>
          <h2 className="premium-modal__title">Free Premium survey</h2>
        </div>
        <div className="premium-modal__info">
          <InfoIcon />
          <div className="premium-modal__info-text">
            Tell us about yourself to get a free premium subscription
          </div>
        </div>
        <div className="premium-modal__form">
          <div>
            <p style={{ margin: 0, ...(formError && !workPlace.trim() ? { color: '#ff4d4f' } : {}) }}>* Where do you work?</p>
            <Input
              type="text"
              placeholder="Your workplace"
              className="premium-modal__input"
              value={workPlace}
              onChange={(e) => setWorkPlace(e.target.value)}
              style={{ ...(formError && !workPlace.trim() ? { borderColor: '#ff4d4f' } : {}) }}
            />
          </div>
          <div>
            <p style={{ margin: 0, ...(formError && !companyName.trim() ? { color: '#ff4d4f' } : {}) }}>* Company name</p>
            <Input
              type="text"
              placeholder="Your company name"
              className="premium-modal__input"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
              style={{ ...(formError && !companyName.trim() ? { borderColor: '#ff4d4f' } : {}) }}
            />
          </div>
          <div>
            <p style={{ margin: 0, ...(formError && !jobPosition.trim() ? { color: '#ff4d4f' } : {}) }}>* At what position?</p>
            <Input
              type="text"
              placeholder="Your position"
              className="premium-modal__input"
              value={jobPosition}
              onChange={(e) => setJobPosition(e.target.value)}
              style={{ ...(formError && !jobPosition.trim() ? { borderColor: '#ff4d4f' } : {}) }}
            />
          </div>
          <div>
            <p style={{ margin: 0, ...(formError && !jobTitle.trim() ? { color: '#ff4d4f' } : {}) }}>* Job title</p>
            <Input
              type="text"
              placeholder="Your job title"
              className="premium-modal__input"
              value={jobTitle}
              onChange={(e) => setJobTitle(e.target.value)}
              style={{ ...(formError && !jobTitle.trim() ? { borderColor: '#ff4d4f' } : {}) }}
            />
          </div>
          {formError && <div className="premium-modal__error-message" style={{margin: '0px'}}>{formError}</div>}
          <div className="premium-modal__actions" style={{ paddingTop: '0px' }}>
            <Button
              style={{ width: '100%', cursor: hasMinimumInput() ? 'pointer' : 'not-allowed' }}
              disabled={!hasMinimumInput()}
              className={`${!hasMinimumInput() ? 'disabled' : ''} modal-action-button`}
              onClick={() => {
                setAttemptedSubmit(true);
                
                if (!workPlace.trim() || !companyName.trim() || !jobPosition.trim() || !jobTitle.trim()) {
                  setFormError('Please, fill in all the fields');
                  return;
                }
                
                logEvent('questionnaire_part1__submit');
                props.onComplete(workPlace, companyName, jobPosition, jobTitle);
              }}
            >
              Continue
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

// Second form - AI detector specific questions
export const AiDetectorQuestionsForm: React.FC<{
  open: boolean,
  workInfo: { workPlace: string, companyName: string, jobPosition: string, jobTitle: string },
  onOk: () => void,
  onCancel: () => void
}> = (props) => {
  const [aiDetectorPurpose, setAiDetectorPurpose] = useState('');
  const [currentSolution, setCurrentSolution] = useState('');
  const [discoverySource, setDiscoverySource] = useState('');
  const [otherTools, setOtherTools] = useState('');
  const [importantFeatures, setImportantFeatures] = useState('');

  const [formError, setFormError] = useState('');
  const [charErrors, setCharErrors] = useState<{ [key: string]: string }>({});
  const [attemptedSubmit, setAttemptedSubmit] = useState(false);

  useEffect(() => {
    if (aiDetectorPurpose.trim() && currentSolution.trim() && discoverySource.trim() && 
        otherTools.trim() && importantFeatures.trim()) {
      setFormError('');
    }
    
    // No character length validation
    setCharErrors({});
  }, [aiDetectorPurpose, currentSolution, discoverySource, otherTools, importantFeatures]);

  const hasMinimumInput = () => {
    return Boolean(aiDetectorPurpose.trim() && currentSolution.trim() && discoverySource.trim() && 
                  otherTools.trim() && importantFeatures.trim());
  };

  return (
    <Modal
      open={props.open}
      onOk={props.onOk}
      onCancel={() => {
        props.onCancel();
        logEvent('questionnaire_part2__close');
      }}
      width={450}
      centered
      footer={null}
      maskClosable={false}
    >
      <div className="premium-modal">
        <div className="premium-modal__header">
          <div className="premium-modal__crown">
            <CrownWhite />
          </div>
          <h2 className="premium-modal__title">Free Premium survey</h2>
        </div>
        <div className="premium-modal__info">
          <InfoIcon />
          <div className="premium-modal__info-text">
            We'll need some time to make sure that you really answered provided questions, so it'll take up to 30 minutes to activate your premium subscription.
          </div>
        </div>
        <div className="premium-modal__form">
          <div>
            <p style={{ margin: 0, ...(formError && !aiDetectorPurpose.trim() ? { color: '#ff4d4f' } : {}) }}>* For what purposes do you need an ai-detector?</p>
            <textarea
              placeholder="Purposes"
              value={aiDetectorPurpose}
              onChange={(e) => setAiDetectorPurpose(e.target.value)}
              className="premium-modal__textarea"
              style={{ ...(formError && !aiDetectorPurpose.trim() || (attemptedSubmit && charErrors.aiDetectorPurpose) ? { borderColor: '#ff4d4f' } : {}) }}
            />
            {attemptedSubmit && charErrors.aiDetectorPurpose && <div className="premium-modal__error-message" style={{margin: '5px 0 0 0', fontSize: '12px'}}>{charErrors.aiDetectorPurpose}</div>}
          </div>
          <div>
            <p style={{ margin: 0, ...(formError && !currentSolution.trim() ? { color: '#ff4d4f' } : {}) }}>* How do you currently solve this tasks?</p>
            <textarea
              placeholder="Your current solutions"
              value={currentSolution}
              onChange={(e) => setCurrentSolution(e.target.value)}
              className="premium-modal__textarea"
              style={{ ...(formError && !currentSolution.trim() || (attemptedSubmit && charErrors.currentSolution) ? { borderColor: '#ff4d4f' } : {}) }}
            />
            {attemptedSubmit && charErrors.currentSolution && <div className="premium-modal__error-message" style={{margin: '5px 0 0 0', fontSize: '12px'}}>{charErrors.currentSolution}</div>}
          </div>
          <div>
            <p style={{ margin: 0, ...(formError && !discoverySource.trim() ? { color: '#ff4d4f' } : {}) }}>* How did you find It's AI?</p>
            <Input
              type="text"
              placeholder="E.g. search, friend, socials, ads"
              className="premium-modal__input"
              value={discoverySource}
              onChange={(e) => setDiscoverySource(e.target.value)}
              style={{ ...(formError && !discoverySource.trim() || (attemptedSubmit && charErrors.discoverySource) ? { borderColor: '#ff4d4f' } : {}) }}
            />
            {attemptedSubmit && charErrors.discoverySource && <div className="premium-modal__error-message" style={{margin: '5px 0 0 0', fontSize: '12px'}}>{charErrors.discoverySource}</div>}
          </div>
          <div>
            <p style={{ margin: 0, ...(formError && !otherTools.trim() ? { color: '#ff4d4f' } : {}) }}>* Have you considered any other ai-detectors and why have you chosen us?</p>
            <textarea
              placeholder="Your choice reason"
              value={otherTools}
              onChange={(e) => setOtherTools(e.target.value)}
              className="premium-modal__textarea"
              style={{ ...(formError && !otherTools.trim() || (attemptedSubmit && charErrors.otherTools) ? { borderColor: '#ff4d4f' } : {}) }}
            />
            {attemptedSubmit && charErrors.otherTools && <div className="premium-modal__error-message" style={{margin: '5px 0 0 0', fontSize: '12px'}}>{charErrors.otherTools}</div>}
          </div>
          <div>
            <p style={{ margin: 0, ...(formError && !importantFeatures.trim() ? { color: '#ff4d4f' } : {}) }}>* What are the most important things for you in ai-detector?</p>
            <textarea
              placeholder="Key priorities"
              value={importantFeatures}
              onChange={(e) => setImportantFeatures(e.target.value)}
              className="premium-modal__textarea"
              style={{ ...(formError && !importantFeatures.trim() || (attemptedSubmit && charErrors.importantFeatures) ? { borderColor: '#ff4d4f' } : {}) }}
            />
            {attemptedSubmit && charErrors.importantFeatures && <div className="premium-modal__error-message" style={{margin: '5px 0 0 0', fontSize: '12px'}}>{charErrors.importantFeatures}</div>}
          </div>
          {formError && <div className="premium-modal__error-message" style={{margin: '0px'}}>{formError}</div>}
          <div className="premium-modal__actions" style={{ paddingTop: '0px' }}>
            <Button
              style={{ width: '100%', cursor: hasMinimumInput() ? 'pointer' : 'not-allowed' }}
              disabled={!hasMinimumInput()}
              className={`${!hasMinimumInput() ? 'disabled' : ''} modal-action-button`}
              onClick={() => {
                setAttemptedSubmit(true);
                
                if (!aiDetectorPurpose.trim() || !currentSolution.trim() || !discoverySource.trim() || 
                    !otherTools.trim() || !importantFeatures.trim()) {
                  setFormError('Please, fill in all the fields');
                  return;
                }
                
                logEvent('questionnaire_part2__submit');
                sendQuestionnaireRequest(
                  props.workInfo.workPlace,
                  props.workInfo.companyName,
                  props.workInfo.jobTitle,
                  props.workInfo.jobPosition,
                  aiDetectorPurpose,
                  currentSolution,
                  discoverySource,
                  otherTools,
                  importantFeatures
                );
                props.onOk();
              }}
            >
              Send
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

// Main container component to manage the flow between forms
export const QuestionareeForFreePremium: React.FC<{ open: boolean, onOk: () => void, onCancel: () => void }> = (props) => {
  const [step, setStep] = useState(1);
  const [workInfo, setWorkInfo] = useState<{
    workPlace: string;
    companyName: string;
    jobPosition: string;
    jobTitle: string;
  } | null>(null);

  const handleWorkInfoComplete = (workPlace: string, companyName: string, jobPosition: string, jobTitle: string) => {
    setWorkInfo({ workPlace, companyName, jobPosition, jobTitle });
    setStep(2);
  };

  const handleCancel = () => {
    setStep(1);
    setWorkInfo(null);
    props.onCancel();
  };

  return (
    <>
      {step === 1 && (
        <WorkInfoForm 
          open={props.open} 
          onComplete={handleWorkInfoComplete} 
          onCancel={handleCancel} 
        />
      )}
      
      {step === 2 && workInfo && (
        <AiDetectorQuestionsForm 
          open={props.open} 
          workInfo={workInfo} 
          onOk={() => {
            setStep(1);
            setWorkInfo(null);
            props.onOk();
          }} 
          onCancel={handleCancel} 
        />
      )}
    </>
  );
};