import { useState, useEffect } from "react";

export const useChangingState = (initialState: any) => {
  const [state, setState] = useState(initialState);

  useEffect(() => {
    setState(initialState);
  }, [initialState]);

  return [state, setState];
}