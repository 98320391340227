import react, {useEffect, useState} from 'react'
import './style.css'
import {
    DownloadCertIcon,
    Spinner,
} from '../icons'
import {logEvent} from "../../utils";
import {createScanReportPDF} from "../../certificate";
import { Tooltip } from 'src/elements/tooltip';
import { useMe } from '../../hooks/useMe';
import { Speedometr } from '../speedometr';
import { FeedbackContainer } from '../feedback';
import { Textarea } from './Textarea';
import { TextareaWidgets } from './TextareaWidgets';
import { TextareaControll } from './TextareaControll';
import { ScanContainer } from './ScanContainer';
import { SecondaryButton, Button } from 'src/pages/application/Login';

export const FileDrop: react.FC = () => {
    const [file, setFile] = react.useState();
    const [text, setText_] = react.useState('');
    const [scannedText, setTextFinal] = react.useState('');

    const [loading, setLoading] = useState(false);
    const [percents, setPercents_] = react.useState(-1);
    const [error, setError] = useState<string | undefined>(undefined);
    const [segments, setSegments] = useState('');
    const [wordsModalOpened, setWordsModalOpened] = useState(false);
    const [textId, setTextId] = useState(-1);

    const setText = (text: string) => {
        setText_(text)
        setPercents_(-1)
    }
    const setPercents = (value: number) => {
        setPercents_(value)
        setTextFinal(text)
    }

    useEffect(() => {
        document.addEventListener('keydown', function (event) {
            const ta = document.getElementById('textarea')
            if (document.activeElement === ta) {
                return;
            }
            if ((event.metaKey && event.key === 'v') || (event.ctrlKey && event.key === 'v')) {
                navigator.clipboard.readText().then((e) => {
                    setText(text + e);
                });
            }
        });
        if (!text.split('\n').join('').length) {
            setSegments('');
        }
    }, [text]);
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.get('text')) {
            setText(urlParams.get('text')!)
        }
    }, []);
    const certAvailable = !loading && percents !== -1
    return <div className='uploader__container'>
        <div>
            <Textarea
                text={text}
                setText={setText}
                setFile={setFile}
                error={error}
                segments={segments}
                setWordsModalOpened={setWordsModalOpened}
            />
            {
                text.length ?
                    <></> :
                    <TextareaWidgets
                        setFile={setFile}
                        setText={setText}
                        file={file}
                        text={text}
                    />
            }
            <TextareaControll
                text={text}
                setError={setError}
                setLoading={setLoading}
                setPercents={setPercents}
                setSegments={setSegments}
                file={file}
                setText={setText}
                wordsModalOpened={wordsModalOpened}
                setWordsModalOpened={setWordsModalOpened}
                setId={setTextId}
                id={textId}
            />
            <ScanContainer/>
        </div>
        <div className='result__container'>
            {
                loading && percents === -1 ?
                    <div className="spin__container-b">
                        <div className="spin">
                            <Spinner/>
                        </div>
                        <div className="spin__container">
                            Scanning...
                        </div>
                    </div> :
                    Number.isNaN(percents) || percents === -1 ?
                        <span className='result__percent'>%</span> :
                        <span className='result__percent-result'>{percents.toFixed(0)}% of AI</span>
            }

            <Speedometr score={Math.max(0, percents)}/>
            {
                (Number.isNaN(percents) || percents === -1) && !loading ?
                    <span className='result__results'>Run scan to see the results</span> :
                    !loading ?
                        percents < 40 ?
                            <div className="spin__container-b human__container-out">
                                <div className="human__container">
                                    Human-written
                                </div>
                            </div> :
                            percents < 60 ?
                                <div className="spin__container-b mixed__container-out">
                                    <div className="mixed__container">
                                        Mixed
                                    </div>
                                </div> :
                                <div className="spin__container-b ai-gen__container">
                                    <div className="red-container">
                                        AI-generated
                                    </div>
                                </div> :
                        <></>
            }
            {
                !loading && percents !== -1 && !Number.isNaN(percents) && percents >= 0 && percents <= 100 && textId !== -1 ?
                    <FeedbackContainer score={percents} id={textId}/> :
                    <></>
            }
            <div style={{marginTop: "auto", display: "flex"}} className={(certAvailable && "primary" || "secondary")}>
            <Tooltip id={2}>
                {certAvailable ? (
                    <Button style={{marginTop: "auto", display: "flex"}} className="primary cert-button" onClick={() => {
                        logEvent('scan__download_certificate');
                        createScanReportPDF(text, percents, 100-percents, !!segments.length)
                    }}>
                        <DownloadCertIcon/>
                        <div style={{marginLeft: "5px"}}/>Certificate
                    </Button>
                ) : (
                    <SecondaryButton style={{marginTop: "auto", display: "flex"}} className="secondary cert-button" disabled>
                        <DownloadCertIcon/>
                        <div style={{marginLeft: "5px"}}/>Certificate
                    </SecondaryButton>
                )}
            </Tooltip>
            </div>

            <div className={"certificate_footer"}>

            </div>

        </div>
    </div>
}