
export const GOOGLE_ID = process.env.REACT_APP_GOOGLE_ID
export const ENV_NAME = (process.env.REACT_APP_ENV).toLowerCase()
export const PRIVY_ID = process.env.REACT_APP_PRIVY_ID
export const WORDS_GRANTED = 50000

export const BASE_URL = process.env.REACT_APP_BASE_URL
export const BACKEND_URL = process.env.REACT_APP_BACKEND_API_URL
export const MIXPANEL_TOKEN = process.env.REACT_APP_MIXPANEL_TOKEN

export const AMPLITUDE_API_KEY = process.env.REACT_APP_AMPLITUDE_API_KEY
export const LOG_EVENTS = process.env.REACT_APP_LOG_EVENTS === "true"
export const LANDING_URL = process.env.REACT_APP_LANDING_URL

export const MIN_LENGTH = 200


console.log("BASE_URL", BASE_URL)
console.log("BACKEND_URL", BACKEND_URL)
console.log("MIXPANEL_TOKEN", MIXPANEL_TOKEN)
console.log("LANDING_URL", LANDING_URL)