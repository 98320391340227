import { useState, useEffect } from 'react';
import { PositiveFeedbackIcon, PositiveFeedbackIconFill } from '../icons';
import { getFeedback } from '../../requests';
import './style.css';

export const FeedbackContainer: React.FC<{ score: number, id: number }> = (props) => {
  const [clicked, setClicked] = useState(-1);

  useEffect(() => {
      if (clicked !== -1) {
          getFeedback(props.id.toString(), clicked === 1 ? 'positive' : 'negative')
      }
  }, [clicked]);

  return <div className="feedback-with-message">
      <div className='feedback__container'>
          <div onClick={() => setClicked(1)}>
              {
                  clicked === 1 ?
                      <PositiveFeedbackIconFill/> :
                      <PositiveFeedbackIcon/>
              }
          </div>
          <div style={{'transform': 'rotate(180deg) translateY(5px)'}} onClick={() => setClicked(0)}>
              {
                  clicked === 0 ?
                      <PositiveFeedbackIconFill/> :
                      <PositiveFeedbackIcon/>
              }
          </div>
      </div>
      {
          clicked !== -1 ?
              <div className="feedback-message">
                  Thanks for your feedback!
              </div> :
              <></>
      }
  </div>
}