import React from 'react';
import styled from 'styled-components';

type RadioSize = 'small' | 'medium' | 'large';

export interface RadioProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size'> {
  label?: string;
  size?: RadioSize;
  active?: boolean;
  onClick?: (event: React.MouseEvent<HTMLInputElement>) => void;
}

const sizes = {
  small: {
    radio: '20px',
    dot: '8px',
    fontSize: '12px',
  },
  medium: {
    radio: '24px',
    dot: '10px',
    fontSize: '14px',
  },
  large: {
    radio: '32px',
    dot: '12px',
    fontSize: '16px',
  },
};

const RadioWrapper = styled.label`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`;

const RadioInput = styled.input<{ $size?: RadioSize; $active?: boolean }>`
  appearance: none;
  padding: 0;
  width: ${props => sizes[props.$size || 'medium'].radio};
  height: ${props => sizes[props.$size || 'medium'].radio};
  border: 2px solid #CACED9;
  border-radius: 50%;
  background: #EEF0F2;
  cursor: pointer;
  position: relative;
  font-family: Satoshi-Medium!important;
  
  ${props => props.$active && `
    border: 2px solid #AAB9FA;
    background: #7C93F7;
    
    &:after {
      content: '';
      position: absolute;
      width: ${sizes[props.$size || 'medium'].dot};
      height: ${sizes[props.$size || 'medium'].dot};
      background: white;
      border-radius: 50%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  `}

  &:checked {
    border: 2px solid #AAB9FA;
    background: #7C93F7;
    
    &:after {
      content: '';
      position: absolute;
      width: ${props => sizes[props.$size || 'medium'].dot};
      height: ${props => sizes[props.$size || 'medium'].dot};
      background: white;
      border-radius: 50%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &:hover:not(:checked):not($active) {
    border-color: #b1b4bd;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(124, 147, 247, 0.2);
  }
`;

const RadioLabel = styled.span<{ $size?: RadioSize }>`
  font-size: ${props => sizes[props.$size || 'medium'].fontSize};
  color: #333;
  font-family: Satoshi-Regular!important;
`;

export const Radio: React.FC<RadioProps> = ({ 
  label, 
  size = 'medium', 
  active, 
  onClick,
  ...props 
}) => {
  const handleClick = (event: React.MouseEvent<HTMLInputElement>) => {
    if (onClick) {
      onClick(event);
    }
  };

  return (
    <RadioWrapper>
      <RadioInput 
        type="radio" 
        $size={size} 
        $active={active} 
        onClick={handleClick}
        {...props} 
      />
      {label && <RadioLabel $size={size}>{label}</RadioLabel>}
    </RadioWrapper>
  );
};

export default Radio; 