import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import {Application} from "./pages/application";
import {Buffer} from "buffer";
import {ContextComponent} from "./hooks/ContextComponent";

window.Buffer = Buffer;

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement,
);

root.render(
    <ContextComponent>
        <Application/>
    </ContextComponent>
);

