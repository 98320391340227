import './style.css';

interface ISpeedometr {
  score: number;
}

export const Speedometr: React.FC<ISpeedometr> = (props) => {
  const {score} = props;
  return <div className='speedometr__container'>
      <img src="/speedometr.png" alt="" width={155}/>
      <img src="/speedometr__arrow.png" alt="" width={70} className='speed__arrow'
           style={{
               transform: `translateY(-7px) translateX(9.5px) rotate(${(score / 100) * 180}deg)`
           }}
      />
      <div className='speedometr__text'>
          <span>Human</span>
          <span>AI</span>
      </div>
  </div>
}