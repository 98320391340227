import { CrownWhite } from '../icons';
import { Modal } from 'antd';
import { logEvent } from '../../utils';
import { useEnrolling } from '../../hooks/useEnrolling';
import './style.css';

export const IncreaseLimitsForm: React.FC<{ open: boolean, onOk: () => void, onCancel: () => void }> = (props) => {
  const {enroll} = useEnrolling();
  return <Modal open={props.open} onOk={props.onOk} onCancel={() => {
      props.onCancel()
      logEvent("premium_limits__close")
  }} width={320} centered footer={null}
                maskClosable={false}>
      <div className="premium-modal">
          <div className="premium-modal__header premium-modal__header3">
              <div className="premium-modal__crown">
                  <CrownWhite/>
              </div>
              <h2 className="premium-modal__title">Subscribe</h2>
          </div>
          <p className="premium-modal__description premium-modal__description3">
              Increase your scan limits up to 100k characters/scan with our premium subscription.
          </p>
          <div className="premium-modal__form">
              <button className="primary modal-action-button" onClick={() => {
                  props.onCancel();
                  logEvent("premium_limits__subscribe")
                  enroll();
                  window.location.replace('/main/subscription?force-start=true')
              }}>
                  Subscribe
              </button>
              <button className="secondary modal-action-button" onClick={() => {
                  props.onCancel();
                  logEvent("premium_limits__later")
              }}>
                  Remind me later
              </button>
          </div>
      </div>
  </Modal>
}