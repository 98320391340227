import react from 'react'
import './style.css'

const Check: react.FC = () => {
    return <svg width="15" height="10" viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.3918 1L5.72705 8.66476" stroke="#F7F9FB" stroke-width="2" stroke-linecap="round"/>
        <path d="M5.72705 8.66465L1.89467 4.83228" stroke="#F7F9FB" stroke-width="2" stroke-linecap="round"/>
    </svg>
}

const Cross: react.FC = () => {
    return <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.99999 1.64896L1.64896 9.99999" stroke="#848998" stroke-width="2" stroke-linecap="round"/>
        <path d="M10.0003 9.99999L1.64928 1.64896" stroke="#848998" stroke-width="2" stroke-linecap="round"/>
    </svg>
}

interface ICheck{
    active: boolean;
    onClick: () => void;
}

export const SmallCheckbox: react.FC<ICheck> = (props) => {
    return (
        <div 
            className={"check__active " + (props.active ? "" : "check__disabled")} 
            onClick={props.onClick}
            style={{
                backgroundColor: !props.active ? '#EEF0F2' : '#7C93F7',
                border: `1px solid ${props.active ? '#FFFFFF59' : '#CACED9'}`,
                borderRadius: '8px',
            }}
        >
            {props.active ? <Check /> : <></>}
        </div>
    )
}

export const Checkbox: react.FC<ICheck> = (props) => {
    return <div className='check__container' onClick={props.onClick}>
        {
            props.active ? <div></div> : <></>
        }
        <div className={"check__active " + (props.active ? "" : "check__disabled")}>
            {
                props.active ?
                <Check /> : 
                <Cross />
            }
        </div>
        {
            !props.active ? <div></div> : <></>
        }
    </div>
}