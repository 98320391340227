import { getErrorBackground, getErrorBorderColor, getErrorFontColor, getErrorIcon, getErrorText } from "../../utils";

interface IDisplayError {
  error: string;
  isPremium?: boolean;
}

export const DisplayError: React.FC<IDisplayError> = (props) => {
  return <div
      className="alert__container"
      style={{
          backgroundColor: getErrorBackground(props.error),
          color: getErrorFontColor(props.error),
          borderColor: getErrorBorderColor(props.error)
      }}
  >
      {
          getErrorIcon(props.error)
      }
      <span
          className='alert__text'
          style={{
              color: getErrorFontColor(props.error)
          }}
      >
          {getErrorText(props.error, props.isPremium)}
      </span>
  </div>
}