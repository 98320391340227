import react, { useEffect, useState } from 'react'
import './style.css'
import { BritishIcon, CheckBoxIcon,Checkmark,CrownWhite, ScanStatsIcon, Zipper } from '../../../../elements/icons'
import { getMe, sendSubscribeRequest } from '../../../../requests'
import { formatBigNumbers } from '../../../../elements/currentPlan'
import { Modal, Popover, Tooltip } from 'antd'
import { usePrivy } from '@privy-io/react-auth'
import {logEvent} from "../../../../utils";
import { PremiumModal } from './PremiumModal'
import { useMe } from '../../../../hooks/useMe';
import { validateEmail } from '../../../../utils';
import { Button, SecondaryButton } from '../../Login'

interface ICircleProgress{
    progress: number;
    wordsUsedSimple: number;
    wordsUsedDeep?: number;
    deepProgress?: number;
}

const PopoverContent: react.FC<{deep: boolean, wordsUsed: number}> = (props) => {
    const {deep, wordsUsed} = props;
    return <div className='popover__container'>
        <span className='popover__header'>{deep ? 'Deep scan' : 'Simple scan'}</span>
        <span className='popover__content'>
            <span className='popover__content-words'>{wordsUsed}</span> words
        </span>
    </div>
}

const CircleProgressBar: react.FC<ICircleProgress> = (props) => {
    return <svg width="250" height="250" viewBox="0 0 250 250" className='circular-progress' style={{'--progress': props.progress, '--progress-deep': props.deepProgress} as any}>
        <g transform='scale(0.224)'>
        <circle className="bg"
            cx="125" cy="125" r="115" fill="none" stroke="rgba(238, 240, 242, 1)" stroke-width="20"
        ></circle>
        {
            props.wordsUsedSimple ? <Popover content={<PopoverContent deep={false} wordsUsed={props.wordsUsedSimple}/>} color='white' overlayStyle={{whiteSpace: 'pre-line'}}>
          <circle className="fg"
              cx="125" cy="125" r="30" fill="none" stroke="rgba(158, 176, 255, 1)" stroke-width="20"
          ></circle>
        </Popover> : null
        }
        {
            props.wordsUsedDeep ? <Popover content={<PopoverContent deep={true} wordsUsed={props.wordsUsedDeep}/>} color='white'>
          <circle className="deep"
              cx="125" cy="125" r="30" fill="none" stroke-width="20"
          ></circle>
        </Popover> : null
        }
        </g>
    </svg>
}

const CurrentPlanContainer: react.FC = () => {
    return <div className='current__plan-widget'>Current plan</div>
}

const PopularContainer: react.FC = () => {
    return <div className='popular__container'>
        <Zipper />
        <span className='popular__cotnainer-text'>Popular</span>
    </div>
}

interface IPremiumCircleStats{
    wordsUsedSimple: number;
    wordsUsedDeep?: number;
    grantedSimple: number;
    grantedDeep?: number;
    header: string | React.ReactNode;
}

export const PremiumStatisticCircle: react.FC<IPremiumCircleStats> = (props) => {
    const {wordsUsedSimple, wordsUsedDeep, grantedSimple, grantedDeep, header} = props;
    return <div className="statistics__circle">
            <div className="circle">
                <div style={{transform: 'scale(0.2)'}}></div>
                <CircleProgressBar 
                    progress={wordsUsedSimple / grantedSimple * 100} 
                    deepProgress={wordsUsedDeep && grantedDeep ? wordsUsedDeep / grantedDeep * 100 : undefined}
                    wordsUsedSimple={wordsUsedSimple}
                    wordsUsedDeep={wordsUsedDeep}
                />
            </div>
            <div className="circle-info">
                <div className="circle-active-words premium__header">
                    {header}
                </div>
                <div className="circle-disabled-words">
                {formatBigNumbers((wordsUsedSimple || 0).toString())}<span className='circle-disabled__words'>/{formatBigNumbers((grantedSimple || 0).toString())} words</span>
                </div>
            </div>
        </div>
}

interface IPremiumStats{
    wordsUsedSimple: number;
    wordsUsedDeep: number;
    grantedSimple: number;
    grantedDeep: number;
    britishUsed: number;
    britishGranted: number;
    isPremium: boolean;
}
const PremiumStatisticsContainer: react.FC<IPremiumStats> = (props) => {
    const {wordsUsedSimple, wordsUsedDeep, grantedSimple, grantedDeep, britishUsed, britishGranted, isPremium} = props;
    try{
        return <div className='premium-stats__container'>
        <PremiumStatisticCircle
            wordsUsedSimple={wordsUsedSimple}
            wordsUsedDeep={wordsUsedDeep}
            grantedSimple={grantedSimple}
            grantedDeep={grantedDeep}
            header={<div className='premium__header-container'>
                <ScanStatsIcon />
                <span>Scan words used</span>
            </div>}
        />
        <PremiumStatisticCircle
            wordsUsedSimple={britishUsed}
            grantedSimple={britishGranted}
            header={<div className='premium__header-container'>
                <BritishIcon />
                <span>Britishizer words used</span>
            </div>}
        />
        <div className="current__plan">
            <span>Current plan</span>
            <button className={ (isPremium ? 'primary' : 'secondary free-plan-btn ') + ' current-plan-btn'}>
                {
                    isPremium ? <CrownWhite /> : <></>
                }
                <span>
                    {
                        isPremium ? 'Premium' : 'Free'
                    }
                </span>
            </button>
        </div>
    </div>
    } catch(e) {
        return <div></div>
    }
}

interface ICheckInfoText{
    text: string;
}
const CheckInfoContainer: react.FC<ICheckInfoText> = (props) => {
    const {text} = props;
    return <div className='prem-checkbox__container' style={{opacity: text === '' ? 0 : 1}}>
        <CheckBoxIcon />
        <span>{text}</span>
    </div>
}


interface IPlanContainer{
    name: string;
    pricing: string;
    plans: string[];
    isPremium: boolean;
    owned: boolean;
    onClick: () => void;
}
const PlanContainer: react.FC<IPlanContainer> = (props) => {
    const {name, pricing, plans, isPremium, owned} = props;
    return <div className='plan__container'>
        <span className="container__type">
            {
                isPremium ? <PopularContainer /> : <div/>
            }
            <span>{name}</span>
            {
                isPremium ? <div style={{width: 117}}></div> : <div/>
            }
        </span>
        <span className="container__type-price">
            <span>{pricing}</span>
            {
                owned ? <CurrentPlanContainer /> : <></>
            }
        </span>
        <div className="check__containers">
            {
                plans.map(e => <CheckInfoContainer text={e}/>)
            }
        </div>
        {isPremium ? (
            <Button  
                className={'plan__container-btn'}
                disabled={owned}
                onClick={() => {
                    props.onClick();
                    if (isPremium) {
                        logEvent('subscription__premium_get_started');
                    } else {
                        logEvent('subscription__basic_get_started');
                    }
                }}
            >
                {owned ? 'Current plan' : 'Get started'}
            </Button>
        ) : (
            <SecondaryButton
                className={'plan__container-btn'}
                disabled={owned}
                onClick={() => {
                    props.onClick();
                    if (isPremium) {
                        logEvent('subscription__premium_get_started');
                    } else {
                        logEvent('subscription__basic_get_started');
                    }
                }}
            >
                {owned ? 'Current plan' : 'Get started'}
            </SecondaryButton>
        )}
    </div>
}


interface ISubsModal{
    open: boolean;
    setOpen: (state: boolean) => void;
}
export const SubscribeModal: react.FC<ISubsModal> = (props) => {

    const [state, setState] = useState(2);
    const {user} = usePrivy();
    const [email, setEmail] = useState('');
    const [emailGetted, setEmailGetted] = useState(false);
    const [name, setName] = useState('');



    useEffect(() => {
        if (user?.email) {
            setEmail(user.email.address);
            setEmailGetted(true);
        }
    }, []);
    // useEffect(() => {
    //     if (state === 2) {
    //         sendSubscribeRequest(email, name, false);
    //     }
    // }, [state]);
    const states = [
        <>
            <span className="subscribe-modal__header">Subscribe</span>
            <span className="subscribe-modal__content">
                We are glad that you've decided to get a premium plan. Leave a request below and our sales team will connect you.
            </span>
            <Button className='sm__request' onClick={
                () => {
                    setState(1);
                    logEvent('subscription_form__send_request');
                }}>Send a request</Button>
        </>,
        <>
            <span className="subscribe-modal__header">We need a contact information</span>
            <span className="subscribe-modal__content">
            We are glad that you are interested in our service. Please leave your email below, and our team will contact you to provide more information.
            </span>

            {
                emailGetted ? <></> : <input className='input__info-subs' onChange={(e) => {
                    setEmail(e.target.value);
                }} placeholder='Enter your email here'/>
            }
            <input className='input__info-subs' onChange={(e) => {
                setName(e.target.value);
            }} placeholder='Enter your name here'/>
            <button className={'primary sm__request ' + (validateEmail(email) && name.length ? '' : 'disabled')} onClick={() => {
                logEvent('subscription_form__provide_information');
                if (validateEmail(email) && name.length) setState(2)
            }}>Provide an information</button>
        </>,
        <>
            <span className="subscribe-modal__header">
                <span>Request sent</span>
                <Checkmark />
            </span>
            <span className="subscribe-modal__content">
            Thank you for joining us! Your premium subscription has been activated.
            Please complete the payment within 3 days to avoid deactivation.
            </span>
            <button className='secondary sm__request' onClick={() => props.setOpen(false)}>OK</button>
        </>
    ]

    return <Modal
        open={props.open}
        footer={''}
        width={400}
        onCancel={() => {props.setOpen(false)}}
        closable={true}
    >
        <div className='sm__container'>
            {states[state]}
        </div>
    </Modal>
}


export const PremiumPage: React.FC = () => {
    const { authenticated, login } = usePrivy();
    const [modalOpen, setModalOpen] = useState(false);
    const [subscribeModalOpen, setSubscribeModalOpen] = useState(false);
    const { user } = useMe();
    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const forceStart = params.get('force-start');
        
        if (forceStart === 'true') {
            setModalOpen(true);
        }
    }, [])
    return <div className='premium__page__container'>
        <PremiumModal open={modalOpen} onOk={() => {
                setModalOpen(false);
                setSubscribeModalOpen(true)
                logEvent("premium__send_request");
            }}
            onCancel={() => {
                setModalOpen(false)
                logEvent("subscription_form__close");
            }}
        />
        <SubscribeModal
            open={subscribeModalOpen}
            setOpen={setSubscribeModalOpen}
        />
        <div className="sized">
            <div className="premium__header">Subscription</div>
            <PremiumStatisticsContainer 
                wordsUsedSimple={user?.used_words_simple_scan || 0} 
                wordsUsedDeep={user?.used_words_deep_scan || 0} 
                grantedSimple={user?.granted_words_simple_scan || 0} 
                grantedDeep={user?.granted_words_deep_scan || 0} 
                isPremium={user?.subscription !== 'Free'}
                britishUsed={user?.used_british_words || 0}
                britishGranted={user?.granted_british_words || 0}
            />
            <div className="premium__header">Manage plans</div>
            <div className="plans">
                <PlanContainer name="Basic" pricing="Free" plans={[
                    "10k simple scan words limit",
                    "1k deep scan words limit",
                    "1k words on Britishizer",
                    "Extension usage",
                    "up to 5k characters per one scan",
                    ""
                ]}
                isPremium={false}
                owned={user?.subscription === 'Free'}
                onClick={() => {}}
                />
                <PlanContainer name="Premium" pricing="$15" plans={[
                    "300k simple scan words limit",
                    "300k deep scan words limit",
                    "Extension usage",
                    "50k words on Britishizer",
                    "ChatGPT Plugin",
                    "up to 100k characters per one scan"
                ]}
                isPremium={true}
                owned={user?.subscription !== 'Free'}
                onClick={() => {
                    if (!authenticated) {
                        login();
                        return;
                    }
                    setModalOpen(true);
                }}
                />
            </div>
        </div>
    </div>
}