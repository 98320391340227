import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { useMe } from './useMe';
import { socialMediaSubscribeRequest } from '../requests';

interface SocialMediaContextType {
  showSocialMediaForm: boolean;
  dontShowAgain: () => void;
  onShare: (platform: 'linkedin' | 'twitter' | 'facebook') => void;
  onCheckSharing: (link: string) => void;
  sharedPlatform: 'linkedin' | 'twitter' | 'facebook' | null;
  setShowSocialMediaForm: (show: boolean) => void;
}

const SocialMediaContext = createContext<SocialMediaContextType | undefined>(undefined);

export const SocialMediaProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { user } = useMe();
  const [showSocialMediaForm, setShowSocialMediaForm] = useState<boolean>(false);
  const [sharedPlatform, setSharedPlatform] = useState<'linkedin' | 'twitter' | 'facebook' | null>(null);
  const [lastWordCount, setLastWordCount] = useState<number>(0);
    
  useEffect(() => {
    if (lastWordCount === 0 || Number.isNaN(lastWordCount)) {
        setLastWordCount(Math.floor(user?.used_words_simple_scan! / 5000) * 5000);
    }
  }, [user])

  // Check if the user has already shared on social media
  const hasShared = () => {
    return localStorage.getItem('socialMediaShared') === 'true';
  };

  // Check if the user has chosen to not show the modal again
  const isDontShowAgain = () => {
    return localStorage.getItem('socialMediaDontShow') === 'true';
  };

  const dontShowAgain = () => {
    localStorage.setItem('socialMediaDontShow', 'true');
    setShowSocialMediaForm(false);
  };


  // Handle sharing on a social media platform
  const onShare = (platform: 'linkedin' | 'twitter' | 'facebook') => {
    setSharedPlatform(platform);
  };

  // Handle checking sharing and making the API request
  const onCheckSharing = (link: string) => {
    if (sharedPlatform) {
      socialMediaSubscribeRequest(sharedPlatform, link);
      localStorage.setItem('socialMediaShared', 'true');
      setShowSocialMediaForm(false);
    }
  };

  useEffect(() => {
    // Only check if we should show the modal if we have user data
    if (user) {
      const isPremium = user.subscription !== 'Free';
      const wordCount = user.used_words_simple_scan;
      
      // Check if the user has reached 9500 words or every 500 words after 10000
      const shouldShowBasedOnWordCount = 
        (wordCount >= 9500 && lastWordCount < 9500) || 
        (wordCount >= 10000 && (wordCount - lastWordCount) >= 5000);
      
      // Update the last word count
      if (shouldShowBasedOnWordCount) {
        setLastWordCount(Math.floor(wordCount / 5000) * 5000);
      }
      console.log(wordCount, lastWordCount, shouldShowBasedOnWordCount, hasShared(), !isDontShowAgain());
      // Show the modal if:
      // 1. The user is not premium
      // 2. They have reached the word count threshold
      // 3. They haven't shared already
      // 4. They haven't chosen to not show the modal again
      // 5. They haven't chosen to be reminded later
      if (shouldShowBasedOnWordCount && !hasShared() && !isDontShowAgain()) {
        setShowSocialMediaForm(true);
      }
    }
  }, [user]);

  const value = {
    showSocialMediaForm,
    dontShowAgain,
    onShare,
    onCheckSharing,
    sharedPlatform,
    setShowSocialMediaForm
  };

  return <SocialMediaContext.Provider value={value}>{children}</SocialMediaContext.Provider>;
};

export const useSocialMediaForm = () => {
  const context = useContext(SocialMediaContext);
  if (context === undefined) {
    throw new Error('useSocialMediaForm must be used within a SocialMediaProvider');
  }
  return context;
};

export default useSocialMediaForm;
