import react, {useEffect, useState} from 'react'
import './styles.css'
import {FileDrop} from '../../../elements/fileDrop'
import {CurrentPlan} from '../../../elements/currentPlan'
import {Chart} from '../../../elements/line'
import {IRow, Table} from '../../../elements/table'
import {Modal} from 'antd'
import {createAPIKey, deleteAPIKey, getAnalytics, getApiKeys, updateAPIKey} from '../../../requests'
import {Selector} from '../../../elements/selector'
import {CopyToClipboardIcon} from '../../../elements/copyToClipboard'
import {logEvent, reprNetworkError} from "../../../utils";
import HistoryPage from './history'
import { Button, Input, SecondaryButton } from '../Login';
import { ShareModal } from '../../../elements/fileDrop/ShareModal';
import useSocialMediaForm from 'src/hooks/useSocialMediaForm'


export const Layout: react.FC = () => {
    const { showSocialMediaForm, setShowSocialMediaForm } = useSocialMediaForm();
    return <div className='layout__container centered-layout'>
        <div className="widd">
            <span className="header">Text scan</span>
            <div className="file__screen">
                <FileDrop/>
            </div>
            <HistoryPage/>
            <ShareModal isOpen={showSocialMediaForm} onClose={() => setShowSocialMediaForm(false)} />
        </div>
    </div>
}

export const APIUsage: react.FC = () => {
    const [chartData, setChartData] = useState([]);
    const [trashold, setTreshold] = useState(7);
    const optionsToTrashold = {
        "7 days": 7,
        "14 days": 14,
        "30 days": 30,
        "90 days": 90,
        "360 days": 360
    }
    const options = [
        "7 days",
        "14 days",
        "30 days",
        "90 days",
        "360 days"
    ];

    useEffect(() => {
        getAnalytics(trashold).then((e) => {
            setChartData(e);
        })
    }, [trashold]);

    return (
        <div className='layout__container'>
            <div className="header">Usage</div>
            <div className="file__screen">
                <CurrentPlan/>
            </div>
            <div className="header__container stats">
                <div className="header">Statistics</div>
                <Selector onSelect={(tr) => {
                    setTreshold((optionsToTrashold as any)[tr]);
                }} options={options}/>
            </div>
            <div className="charts">
                <div className="chart-row">
                    <div className="chart-with-header">
                        <div className="chart-with-header__header">Scan</div>
                        <Chart
                            header='Words, per day'
                            chartData={[{
                                id: 'Simple scan',
                                data: chartData.map((e: any) => ({
                                    y: e.text,
                                    x: e.time
                                }))
                            },
                                {
                                    id: 'Deep scan',
                                    data: chartData.map((e: any) => ({
                                        y: e.deep_scan_words,
                                        x: e.time
                                    }))
                                }]}
                        />
                    </div>
                    <div className="chart-with-header">
                        <div className="chart-with-header__header">Britishizer</div>
                        <Chart
                            header='Words, per day'
                            chartData={[{
                                id: 'Britishizer',
                                data: chartData.map((e: any) => ({
                                    y: e.british_words,
                                    x: e.time
                                }))
                            }]}
                        />
                    </div>
                </div>
                <div className="chart-row">
                    <div className="chart-with-header">
                        <div className="chart-with-header__header silent">Scan1</div>
                        <Chart
                            header='Queries, per day'
                            chartData={[{
                                id: 'Scan',
                                data: chartData.map((e: any) => ({
                                    y: e.queries,
                                    x: e.time
                                }))
                            }]}
                        />
                    </div>
                    <div className="chart-with-header">
                        <div className="chart-with-header__header silent">Britishizer1</div>
                        <Chart
                            header='Queries, per day'
                            chartData={[{
                                id: 'Britishizer',
                                data: chartData.map((e: any) => ({
                                    y: e.british_queries,
                                    x: e.time
                                }))
                            }]}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

interface ITableModal {
    isModalOpen: boolean;
    setIsModalOpen: (state: boolean) => void;
    onClick: (state: any) => void;
}

interface ICreateAPIKeyModal extends ITableModal {
    onClick: (state: any) => Promise<string>;
    updateStates: () => void;
}

enum MODALS {
    CREATE_MODAL,
    AFTER_CREATE_MODAL,
    CHANGE_MODAL,
    DELETE_MODAL,
    NONE
}

const CreateAPIKeyModal: react.FC<ICreateAPIKeyModal> = (props) => {
    const {isModalOpen, setIsModalOpen} = props;
    const [text, setText] = useState('');
    const [id, setId] = useState('');
    const [stage, setStage] = useState(0);

    const stages = [
        <>
            <div className='modal__container' style={{marginBottom: '0px!important'}}>
                <Input
                    type="text"
                    placeholder='My new key (optional)'
                    onChange={(e) => {
                        setText(e.target.value);
                    }}
                />
            </div>
        </>,
        <>
            <div className='modal__text-container'>
                <span
                    className='modal__text fz16'>Do not share the key with anyone. Now you can copy your API key.</span>
                <div className="api-key_text__container">
                    <span className="api-key__text">{id}</span>
                    <CopyToClipboardIcon textToCopy={id}/>
                </div>
            </div>
        </>
    ];
    const titles = ['New API Key', 'Key created']
    const footers = [
        <div className='footer__btns'>
            <SecondaryButton className='inline' onClick={() => {
                setIsModalOpen(false);
            }}>Cancel
            </SecondaryButton>
            <Button
                className='inline'
                onClick={() => {
                    props.onClick(text).then((e) => {
                        setId(e);
                        setStage(1);
                    });
                }}
            >Create key
            </Button>
        </div>,
        <div className='footer__btns'>
            <SecondaryButton className='inline' onClick={() => {
                setIsModalOpen(false);
                setStage(0);
                props.updateStates();
            }}>OK
            </SecondaryButton>
        </div>
    ]
    return <Modal
        title={titles[stage]}
        open={isModalOpen}
        onClose={()=>setIsModalOpen(false)}
        onCancel={()=>setIsModalOpen(false)}
        footer={[
            footers[stage]
        ]}
    >
        {stages[stage]}
    </Modal>
}

interface IDeleteAPIModal extends ITableModal {
    title: string
}

export const DeleteAPIKeyModal: react.FC<IDeleteAPIModal> = (props) => {
    const {isModalOpen, setIsModalOpen} = props;
    return <Modal
        title='Delete API Key'
        open={isModalOpen}
        footer={[
            <div className='footer__btns'>
                <SecondaryButton className='inline' onClick={() => {
                    setIsModalOpen(false);
                }}>Cancel
                </SecondaryButton>
                <Button
                    className='inline'
                    onClick={() => {
                        props.onClick("");
                    }}
                >Delete
                </Button>
            </div>
        ]}
    >
        <div className='modal__text-container'>
            <span className='modal__text'>Are you sure to delete the key <strong>{props.title}</strong>?</span>
        </div>
    </Modal>
}

interface IChangeAPIModal extends ITableModal {
    title: string;
    setTitle: (title: string) => void;
}

export const ChangeAPIModal: react.FC<IChangeAPIModal> = (props) => {
    const {isModalOpen, setIsModalOpen} = props;
    return <Modal
        title='New API Key'
        open={isModalOpen}
        footer={[
            <div className='footer__btns'>
                <SecondaryButton className='inline' onClick={() => {
                    setIsModalOpen(false);
                }}>Cancel
                </SecondaryButton>
                <Button
                    className='inline'
                    onClick={() => {
                        props.onClick("");
                    }}
                >Change key
                </Button>
            </div>
        ]}
    >
        <div className='modal__container'>
            <Input
                type="text"
                placeholder='My new key (optional)'
                onChange={(e) => {
                    props.setTitle(e.target.value);
                }}
                value={props.title}
            />
        </div>
    </Modal>
}

export const APITable: react.FC = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [tableData, setTableData] = useState<IRow[]>([]);
    const [reloadTableData, setReloadTableData] = useState(false);
    const [activeModal, setActiveModal] = useState(MODALS.NONE);
    const [activeKey, setActiveKey] = useState('');
    const [activeName, setActiveName] = useState('');

    useEffect(() => {
        getApiKeys().then((e) => {
            setTableData(e.filter((ee: any) => ee));
        });
    }, [reloadTableData]);

    var title = '';
    try {
        title = tableData.filter(e => e.apikey == activeKey)[0].name;
    } catch {

    }

    return <div className='layout__container'>
        {
            activeModal == MODALS.NONE ?
                <></> :
                activeModal == MODALS.CREATE_MODAL ?
                    <CreateAPIKeyModal
                        isModalOpen={isModalOpen}
                        setIsModalOpen={setIsModalOpen}
                        onClick={async (e) => {
                            try {
                                const ee = await createAPIKey(e);
                                logEvent("api__create_key")
                                return ee.apikey;
                            } catch (e) {
                                logEvent("api__create_key_error", reprNetworkError(e))
                                return ""
                            }
                        }}
                        updateStates={() => {
                            setReloadTableData(!reloadTableData);
                        }}
                    /> :
                    activeModal == MODALS.DELETE_MODAL ?
                        <DeleteAPIKeyModal
                            isModalOpen={isModalOpen}
                            setIsModalOpen={setIsModalOpen}
                            onClick={(_: string) => {
                                deleteAPIKey(activeKey).then((ee) => {
                                    setIsModalOpen(false)
                                    setReloadTableData(!reloadTableData);
                                    logEvent("api__delete_key")
                                }).catch((e) => {
                                    logEvent("api__delete_key_error", reprNetworkError(e))
                                })
                            }}
                            title={title}
                        /> :
                        activeModal == MODALS.CHANGE_MODAL ?
                            <ChangeAPIModal
                                isModalOpen={isModalOpen}
                                setIsModalOpen={setIsModalOpen}
                                onClick={async (e: string) => {
                                    updateAPIKey(activeKey, activeName).then((e) => {
                                        setIsModalOpen(false);
                                        setReloadTableData(!reloadTableData);
                                    })
                                }}
                                title={activeName}
                                setTitle={(title: string) => {
                                    setActiveName(title);
                                }}
                            /> : <></>
        }
        <div className="header">
            API keys
        </div>

        <div className="file__screen">
            <Table
                data={tableData}
                onChange={(key: string) => {
                    setActiveKey(key);
                    setActiveName(tableData.filter((e) => e.apikey == key)[0].name);
                    setActiveModal(MODALS.CHANGE_MODAL);
                    setIsModalOpen(true)
                }}
                onDelete={(key: string) => {
                    setActiveKey(key);
                    setActiveModal(MODALS.DELETE_MODAL);
                    setIsModalOpen(true);
                }}
            />
            <div className="create-new-key">
                <Button className='inline' onClick={() => {
                    logEvent("api__create_new_key_click")
                    setIsModalOpen(true);
                    setActiveModal(MODALS.CREATE_MODAL);
                }}>+ Create new key
                </Button>
            </div>
        </div>
    </div>
}