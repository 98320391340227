import react, {useEffect} from 'react'
import './style.css'
import { Checkmark, Logo } from '../../../elements/icons'
import { useSystemLogin } from '../main'
import {logEvent} from "../../../utils";
import { Button } from '../Login';


export const ExtensionLogin: react.FC = () => {
    const {ready, authenticated} = useSystemLogin(true);
    useEffect(() => {
        if (ready && authenticated) {
            logEvent('extension__login__authenticated');
        }
    }, [ready, authenticated]);
    return <>
    {
        ready && authenticated ? <div className='extension-login__container'>
        <div className="extension-login__centered">
            <div className="extension-login__content">
                <Logo />
                <div className="extension-logo__content-actions">
                    <div className="information__container">
                        <div className="information">
                            <Checkmark />
                            <span>Login successful</span>
                        </div>
                        <span>Now you can start using the extension</span>
                    </div>
                    <div className="actions__centered">
                        <div className="actions__container">
                            <Button className='primary' onClick={() => {
                                logEvent('extension__login__continue');
                                window.location.replace('/main/scan')
                            }}>Continue</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> : <></>
    }
    </>
    
}