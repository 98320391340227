import { UserProvider } from "./useMe";
import { OnboardingProvider } from "./useOnboarding";
import { EnrollingProvider } from "./useEnrolling";
import { SocialMediaProvider } from "./useSocialMediaForm";

export const ContextComponent = (props: {children: React.ReactNode}) => {
    return (
		    <UserProvider>
                <OnboardingProvider>
                    <EnrollingProvider>
                        <SocialMediaProvider>
                            {props.children}
                        </SocialMediaProvider>
                    </EnrollingProvider>
                </OnboardingProvider>
            </UserProvider>
    )
}