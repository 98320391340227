import {ENV_NAME, GOOGLE_ID, LOG_EVENTS, MIN_LENGTH} from "./config";
import {sendEventRequest} from "./requests";
import LanguageDetect from "languagedetect";
import * as amplitude from '@amplitude/analytics-browser';
import ReactGA from "react-ga4";
import { AlertIcon, SubscriptionErrorIcon } from "./elements/icons";

ReactGA.initialize(GOOGLE_ID!);

export const reprNetworkError = (error: any) => {
    console.log("Request error", error)
    if (error.response) {
        return {
            ...(error.response.data || {}),
            status: error.response.status
        }
    } else if (error.request) {
        return {
            error: "No response from server",
            message: error.message
        }
    } else {
        return {
            error: error.message
        }
    }
}

export const logEvent =  async (eventName: string, options = {}) => {
    if (!LOG_EVENTS) {
        console.log("Event (logging disabled)", eventName, options)
        return
    }
    const fullEventName = ENV_NAME + "__" + eventName;
    console.log("Event", fullEventName, options)

    ReactGA.event(fullEventName, options)
    amplitude.logEvent(fullEventName, options);
    return await sendEventRequest(fullEventName, options);
}
(window as any).logEvent = logEvent

export function checkIsEnglish(str: string) {
    const lngDetector = new LanguageDetect();
    const languages = lngDetector.detect(str, 2);
    return languages.filter((l) => l[0] === 'english').length > 0;
}

export function getErrorFontColor(errorText: string) {
    if (errorText.includes("typing more text")) {
        return 'rgba(125, 37, 47, 1)';
    } else {
        return 'rgba(117, 81, 24, 1)'
    }
}

export function getErrorBackground(errorText: string) {
    if (errorText.includes("typing more text")) {
        return 'rgba(255, 247, 244, 1)';
    } else {
        return 'rgba(254, 247, 209, 1)'
    }
}

export function getErrorBorderColor(errorText: string | undefined) {
    console.log(errorText);
    if (errorText?.includes("typing more text")) {
        return 'rgba(241, 200, 200, 1)';
    } else {
        return 'rgba(247, 199, 82, 1)'
    }
}

export function getErrorIcon(errorText: string) {
    if (errorText.includes("typing more text")) {
        return <AlertIcon/>;
    } else {
        return <SubscriptionErrorIcon/>
    }
}

export function getErrorText(errorText: string, isPremium?: boolean) {
    console.log(errorText, isPremium);
    if (errorText.includes("typing more text")) {
        return `Not enough text. Please, type more than ${MIN_LENGTH} characters.`;
    }
    if (errorText === 'service isn`t available at the moment') {
        return 'Service is temporarily unavailable. Please, try again later.';
    }
    if (errorText === 'Only English is supported') {
        return 'Only English is supported';
    }
    if (errorText === "depscan") {
        return "You've reached your deep scan words limit, upgrade to the Premium plan to continue using deep scan mode."
    } 
    if (errorText === "You don't have enough deep scan words.") {
        if (isPremium) {
            return 'You don\'t have enough deep scan words'
        } else {
            return 'You don\'t have enough deep scan words. Get premium plan to continue using deep scan mode.'
        }
    }
    else {
        if (isPremium) {
            return 'Limit reached.'
        } else {
            return 'Free plan words limit reached. Get premium plan to increase your limits.'
        }
    }
}

export function validateEmail(email: string) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
}

export function getClientIdFromCookie() {
    const cookie = document.cookie.split('; ').find(row => row.startsWith('_ga='));
    if (!cookie) return null;
    const gaCookie = cookie.split('=')[1];
    const parts = gaCookie.split('.');
    return parts.length >= 3 ? parts[2] + "." + parts[3] : null;
}
