import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { FileAdd, CopyTextIcon } from '../icons';
import { MIN_LENGTH } from '../../config';
import { Tag } from '../tag';

interface ITextareaWidgets {
  setFile: (file: any) => void;
  file: any;
  setText: (text: string) => void;
  text: string;
}

export const TextareaWidgets: React.FC<ITextareaWidgets> = (props) => {
  const {setFile, file, setText, text} = props;
  const onDrop = useCallback((acceptedFiles: any) => {
      setFile(
          acceptedFiles[0]
      );

  }, []);
  const accept = {
      'application/pdf': ['.pdf'],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document": ['.docx'],
      'text/plain': ['.txt']
  }
  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop, accept, maxSize: 5242880 / 5});
  return <div className='drop__container'>
      <div className="poschange__1">
          <div {...getRootProps()}
               className={'filedrop__container ' + (isDragActive ? 'filedrop__container-active' : "")}>
              <input {...getInputProps()} />
              <div className="filedrop__content" style={{borderRadius: '10px'}}>
                  <FileAdd/>
                  <span className='upload__head'>
                  {
                      file ?
                          (file as any).path :
                          isDragActive ?
                              "Drag document here" :
                              "Click to upload document"
                  }
              </span>
                  <div className="tags">
                      <Tag>PDF</Tag>
                      <Tag>DOCX</Tag>
                      <Tag>TXT</Tag>
                      <Tag bordered>{"<1 MB"}</Tag>
                  </div>
              </div>
          </div>
      </div>
      <div className="filedrop__container or__container" style={{position: 'static'}}>
          or
      </div>
      <div className="poschange__2">
          <div className='filedrop__container filedrop__container1' onClick={() => {
              navigator.clipboard.readText().then((e) => {
                  setText(text + e);
              });
          }}>
              <div className="filedrop__content">
                  <CopyTextIcon/>
                  <span className='upload__head'>
                  Click to paste text
              </span>
                  <div className="tags">
                      {/* <Tag>PDF</Tag>
                  <Tag>DOCX</Tag> */}
                      <Tag bordered>{`>${MIN_LENGTH} characters`}</Tag>
                  </div>
              </div>
          </div>
      </div>
  </div>
}