import React, { useRef, useState } from 'react';
import { SmallCheckbox } from 'src/elements/checkbox';
import { Logo, SmallLogo } from 'src/elements/icons';
import { Radio, RadioGroup } from '../../../elements/radio';
import styled from 'styled-components';
import { acceptPolicyRequest, setNameAndRoleRequest } from 'src/requests';
import { LANDING_URL } from 'src/config';
const LoginContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  min-height: 70vh;
`;

const LoginCard = styled.div`
  padding: 40px;
  border-radius: 12px;
  width: 100%;
  max-width: 500px;
`;


const Title = styled.h1`
  text-align: center;
  margin-bottom: 2rem;
  font-size: 1.5rem;
  font-weight: 600;
  color: #333;
  margin: 0;
`;

export const Input = styled.input`
  padding: 16px;
  margin-bottom: 1rem;
  border: 2px solid #e2e8f0;
  border-radius: 8px;
  font-size: 1rem;
  outline: none;
  width: calc(100% - 36px);
  
  &:focus {
    border-color: #7C93F7;
  }
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  gap: 0.5rem;
`;


const PrivacyText = styled.span`
  font-size: 0.875rem;
  color: #4a5568;
  
  a {
    color: #6366f1;
    text-decoration: none;
    
    &:hover {
      text-decoration: underline;
    }
  }
`;

export const Button = styled.button`
  width: 100%;
  padding: 0.75rem;
  background-color: #7C93F7;
  color: white;
  border: 2px solid #FFFFFF40;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.2s;
  
  &:hover {
    background-color: #9EB0FF;
    border: 2px solid #FFFFFF40;
  }
  
  &:disabled {
    background-color: #EEF0F2;
    cursor: not-allowed;
    color: #A6A8B0;
  }
  &:disabled:hover {
    background-color: #EEF0F2;
    border: 2px solid #FFFFFF40;
    color: #A6A8B0;
  }
  &.inline{
    width: fit-content;
    padding: 16px 48px;
  }
`;

export const SecondaryButton = styled(Button)`
  background-color: #F7F9FB;
  border: 2px solid #EDF1F9;
  border-radius: 6px;
  color: #5C5E64;

  &:hover {
    background-color: #EEF0F2;
    border-color: #EDF1F9;
    color: #687EDE;
  }
  
  &:disabled:hover {
    background-color: #EEF0F2;
    border: 2px solid #FFFFFF40;
    color: #A6A8B0;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
`;

const SpecifyInput = styled.input`
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #EDF1F9;
  border-radius: 4px;
  font-size: 14px;
  margin-left: 32px;
  
  &:focus {
    outline: none;
    border-color: #6B84F0;
  }
`;

const JobRole: React.FC<{ onContinue: (role: string) => void }> = (props) => {
    const [selected, setSelected] = useState<string>('');
    const [otherSpecify, setOtherSpecify] = useState<string>('');

    return (
        <Container>
            <Title>One last step</Title>
            <RadioGroup
                selected={selected}
                onChange={setSelected}
                size="medium"
            >
				<p style={{width: '100%', marginLeft: 80, color: '#5C5E64'}}>At what position do you work?</p>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: 'calc(100% - 80px)' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
                        <Radio value="teacher" label="Teacher" />
                        <Radio value="writer" label="Writer/Journalist" />
                        <Radio value="manager" label="Manager" />
                        <Radio value="programmer" label="Programmer" />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
                        <Radio value="student" label="Student" />
                        <Radio value="hr" label="HR" />
                        <Radio value="marketer" label="Marketer" />
                        <Radio value="dont-share" label="Don't want to share" />
                    </div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', width: 'calc(100% - 80px)' }}>
                    <Radio value="other" label="Other" />
                    {selected === 'other' && (
                        <SpecifyInput
                            type="text"
                            placeholder="Specify..."
                            value={otherSpecify}
                            onChange={(e) => setOtherSpecify(e.target.value)}
                        />
                    )}
                </div>
            </RadioGroup>
			<div style={{width: 'calc(100% - 80px)'}}>
				<Button onClick={
          () => props.onContinue(selected === 'other' ? otherSpecify : selected)} 
          disabled={(selected === '' && ((selected as any) !== 'other' && otherSpecify === ''))}
        >
          Sign up
        </Button>
			</div>
        </Container>
    );
};

interface INameAndPolicyInputs {
	onContinue: (name: string) => void;
}

const NameAndPolicyInputs: React.FC<INameAndPolicyInputs> = (props) => {
    const [name, setName] = useState('');
    const [agreedToPrivacy, setAgreedToPrivacy] = useState(false);

	const handleSubmit = (e: React.FormEvent) => {
		e.preventDefault();
		props.onContinue(name);
	};
    return <>
        <Title style={{marginBottom: 20}}>Welcome to It's AI</Title>
        <form onSubmit={handleSubmit}>
            <Input
                type="text"
                placeholder="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
            />
            <CheckboxContainer>
                <SmallCheckbox
                    active={agreedToPrivacy}
                    onClick={() => setAgreedToPrivacy(!agreedToPrivacy)}
                />
                <PrivacyText>
                    I agree with <a href="/privacy-policy" style={{textDecoration: 'underline'}}>Privacy policy</a>
                </PrivacyText>
            </CheckboxContainer>
            <Button type="submit" disabled={!name || !agreedToPrivacy}>
                Continue
            </Button>
        </form>
    </>
}

const Login: React.FC = () => {
	const [step, setStep] = useState(0);
	const nameRef = useRef<string>('');

	return (
		<LoginContainer>
			<LoginCard>
				<div style={{ 
					display: 'flex', 
					alignItems: 'start', 
					gap: '10px', 
					justifyContent: 'center', 
					paddingBottom: '45px' 
				}}>
					<Logo />
				</div>
				<div style={{ 
					backgroundColor: 'white', 
					padding: '20px', 
					borderRadius: '10px', 
					border: '1px solid #EDF1F9' 
				}}>
					{step === 0 ? <NameAndPolicyInputs onContinue={(name: string) => {
						setStep(1);
						nameRef.current = name;
						acceptPolicyRequest();
					}} /> : <JobRole onContinue={(role: string) => {
						setNameAndRoleRequest(nameRef.current, role).then((e) => {
						const params = new URLSearchParams(window.location.search);
						window.location.href = params.get('landing') === 'true' ? LANDING_URL! : '/main/scan';
						});
					}} />}
				</div>
			</LoginCard>
		</LoginContainer>
	);
};

export default Login; 