import react, { useEffect, useState } from 'react'
import './style.css'
import { getMe } from '../../requests';
import { useNavigate } from 'react-router-dom';
import {logEvent} from "../../utils";
import { PremiumStatisticCircle } from '../../pages/application/main/PremiumPage';
import { BritishIcon, ScanStatsIcon } from '../icons';
import { useMe } from '../../hooks/useMe';
import { Button } from '../../pages/application/Login';
export const formatBigNumbers = (number: string) => {
    return number.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}


export const CurrentPlan: react.FC = () => {
    const navigate = useNavigate();
    const { user } = useMe();


    return <div className='current-plan__container'>
        <div className="current-plan__content">
        <PremiumStatisticCircle
            wordsUsedSimple={user?.used_words_simple_scan || 0}
            wordsUsedDeep={user?.used_words_deep_scan || 0}
            grantedSimple={user?.granted_words_simple_scan || 0}
            grantedDeep={user?.granted_words_deep_scan || 0}
            header={<div className='premium__header-container'>
                <ScanStatsIcon />
                <span>Scan words used</span>
            </div>}
        />
        <PremiumStatisticCircle
            wordsUsedSimple={user?.used_british_words || 0}
            grantedSimple={user?.granted_british_words || 0}
            header={<div className='premium__header-container'>
                <BritishIcon />
                <span>Britishizer words used</span>
            </div>}
        />
        </div>
        <Button className='get-more-words-btn' onClick={() => {
            logEvent('statistics__get_more_words');
            navigate('/main/subscription');
        }}>Get More Words</Button>
    </div>
}