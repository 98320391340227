import react, { useState } from 'react'
import { ArrowIcon } from '../icons'
import './style.css'
import { usePrivy } from '@privy-io/react-auth';
import { getLogin } from '../../pages/application/main';
import {logEvent} from "../../utils";

export const Avatar: react.FC = () => {
    const [active, setActive] = useState(false);
    const {logout, user, authenticated} = usePrivy();

    const account = getLogin(user);

    if (!authenticated) {
        return <></>
    }

    return <div className={'avatar__container ' + (active ? "avatar-active__container" : "")} onClick={() => {
        setActive(!active);
    }}>
        <div className={"avatar__menu " + (active ? "avatar-menu__active" : "")}>
            <span className='avatar-menu__item red' onClick={() => {
                setActive(false);
                logEvent("user__logout").finally(() => {
                    localStorage.removeItem('token')
                    logout().then((e) => {
                        window.location.reload()
                    });
                })

            }}>Log out</span>
        </div>
        <div className="avatar">{account.slice(0,2)}</div>
        <div className="avatar__content">
            <div className="avatar__name">{account.slice(0, 15) + (account.length > 15 ? '...' : '')}</div>
            <div className="checked">
                <ArrowIcon />
            </div>
        </div>

    </div>
}