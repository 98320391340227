import React from 'react';
import './style.css';
interface ITag {
  children: string;
  bordered?: boolean;
}

export const Tag: React.FC<ITag> = (props) => {
  return <span className={'tag__container ' + (props.bordered ? 'tag__bordered' : "")}>{props.children}</span>
}
