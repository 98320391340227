import React, { createContext, useContext, useState, useCallback, useMemo } from 'react';
import { useMe } from './useMe';
import { sendSubscribeRequest } from 'src/requests';
import { logEvent } from 'src/utils';

interface EnrollingContextType {
    isEnrolled: boolean;
    scanCount: number;
    incrementEnrollCount: () => void;
    enroll: (send_request?: boolean) => Promise<void>;
	shouldShowEnrollModal: boolean;
}

const EnrollingContext = createContext<EnrollingContextType>({
    isEnrolled: false,
    scanCount: 0,
    incrementEnrollCount: () => {},
    enroll: () => Promise.resolve(),
	shouldShowEnrollModal: false,
});

export const EnrollingProvider: React.FC<{children: React.ReactNode}> = ({ children }) => {
    const [scanCount, setScanCount] = useState(() => parseInt(localStorage.getItem('scanCount') || '0', 10));
	const {user, enrollUser, refreshLimits} = useMe();
    const incrementEnrollCount = useCallback(() => {
        const newCount = scanCount + 1;
        setScanCount(newCount);
        localStorage.setItem('scanCount', newCount.toString());
    }, [scanCount]);

	const shouldShowEnrollModal = useMemo(() => {
		return !user?.enrolled && 
			(user?.scan_count === 2 || user?.scan_count === 9) && 
			user?.subscription === 'Free';
	}, [user]);

    const enroll = async (send_request = true) => {
        await enrollUser();
        if (send_request) {
            await sendSubscribeRequest(true);
        }
        logEvent('free_premium__enroll');
        refreshLimits();
    }

    return (
        <EnrollingContext.Provider 
            value={{
                isEnrolled: user?.enrolled as boolean,
                scanCount,
                incrementEnrollCount,
                enroll,
				shouldShowEnrollModal
            }}
        >
            {children}
        </EnrollingContext.Provider>
    );
};

export const useEnrolling = () => {
    const context = useContext(EnrollingContext);
    if (!context) {
        throw new Error('useEnrolling must be used within an EnrollingProvider');
    }
    return context;
};
