import react, {useEffect, useRef, useState} from 'react'
import {useLogin, usePrivy} from '@privy-io/react-auth'
import {getToken, mapAnonUser, signUp} from '../../../requests';
import {APITable, APIUsage, Layout} from './layout';
import {Sidebar} from '../../../elements/sidebar';
import {PremiumPage} from './PremiumPage';
import {Britishizer} from "./britishizer";
import {logEvent, reprNetworkError} from "../../../utils";
import HistoryPage from './history';
import {Puff} from 'react-loader-spinner'
import styled from "styled-components";
import {useOnboarding} from 'src/hooks/useOnboarding';
import * as amplitude from '@amplitude/analytics-browser';
import {AMPLITUDE_API_KEY, GOOGLE_ID} from "../../../config";
import useMe from 'src/hooks/useMe';
import ReactGA from "react-ga4";

export const getLogin = (user: any) => {
    if (user == null) return "Unknown";
    if (user.email) return user.email.address;
    if (user.wallet) return user.wallet.address;
    if (user.github) return user.github.username;
    if (user.google) return user.google.email;
    return "";
}

const getRegisteredSource = (user: any) => {
    if (user.email) return "Email";
    if (user.wallet) return "Wallet";
    if (user.github) return "Github";
    if (user.google) return "Google";
    return "Unknown";
}


const setupBackendToken = async (user: any) => {
    const login = getLogin(user);
    const e = await signUp(user.id, login, getRegisteredSource(user))
    localStorage.setItem('token', e.token);
    if (localStorage.getItem('from')) {
        mapAnonUser(localStorage.getItem('from')!).finally(() => {
            localStorage.removeItem('from');
        });
    }
    return e.token
}


export const useSystemLogin = (isExtension: boolean = false) => {
    const {authenticated, ready, user} = usePrivy();
    const wasRequest = useRef(false);
    const [token, setToken] = useState(localStorage.getItem("token") || null);
    const {startOnboarding} = useOnboarding();
    const {login: loginMe} = useMe();

    const eventPrefix = isExtension ? 'extension__' : '';

    const {login} = useLogin({
        onComplete: (user, isNewUser, wasAlreadyAuth) => {
            let login = getLogin(user);

            amplitude.setUserId(login)
            localStorage.setItem('itsai_user_id', login);
            console.log("Privy user", user, "isNewUser", isNewUser, "wasAlreadyAuth", wasAlreadyAuth);

            if (wasAlreadyAuth) return;
            if (localStorage.getItem('token') != null) {
                if (isNewUser) {
                    startOnboarding();
                    logEvent(eventPrefix + "user__signup", user)
                    loginMe(user.id, getLogin(user), getRegisteredSource(user)).then(() => {
                        window.location.href = '/login?token=' + localStorage.getItem('token');
                    });
                } else {
                    logEvent(eventPrefix + "user__login", user)
                    loginMe(user.id, getLogin(user), getRegisteredSource(user))
                }
                return
            }
            if (isNewUser) {
                startOnboarding();
                signUp(user.id, login, getRegisteredSource(user)).then((e) => {
                    localStorage.setItem('token', e.token);
                    setToken(e.token);
                    if (localStorage.getItem('from')) {
                        mapAnonUser(localStorage.getItem('from')!).finally(() => {
                            localStorage.removeItem('from');
                        });
                    }
                    logEvent(eventPrefix + "user__signup", user)
                    loginMe(user.id, getLogin(user), getRegisteredSource(user)).then(() => {
                        window.location.href = '/login?token=' + e.token;
                    });
                }).catch((e) => {
                    logEvent(eventPrefix + "user__signup_error_server", reprNetworkError(e))
                })
            } else {
                setupBackendToken(user).then(token => {
                    logEvent(eventPrefix + "user__login", user)
                    setToken(token);
                    loginMe(user.id, getLogin(user), getRegisteredSource(user))
                }).catch(e => {
                    logEvent(eventPrefix + "user__login_error_server", reprNetworkError(e))
                })
            }

        },
        onError: (err_code) => {
            logEvent(eventPrefix + "user__login_error_" + err_code.toLowerCase())
            setTimeout(() => {
                window.location.reload();
            }, 50);
        }
    });
    useEffect(() => {
        const username = getLogin(user)
        // @ts-ignore
        amplitude.init(AMPLITUDE_API_KEY, getLogin(user), {
            serverZone: "EU",
            autocapture: true
        });
        if (username) {
            ReactGA.gtag('config', GOOGLE_ID, {
                'user_id': getLogin(user)
            });
        }

        console.log("Init amplitude and ga4 with user", getLogin(user))

        if (!authenticated && ready && !wasRequest.current) {
            wasRequest.current = true;
            login();
        } else if (authenticated && !token) {
            setupBackendToken(user).then(token => setToken(token));
        }
    }, [authenticated, login, ready, user]);

    return {ready, authenticated, tokenReady: token != null};
}

const CenterContainer = styled.div`
  display: flex;
  width: 95vw;
  height: 95vh;
  justify-content: center;
  align-items: center;
`


export const Main: react.FC = () => {
    const {ready, tokenReady} = useSystemLogin();
    // const {user, acceptPolicy, setName} = useMe();
    var pathname = window.location.pathname.split('/');
    var lastpath = pathname[pathname.length - 1];
    if (lastpath === 'main' || lastpath.length === 0) {
        window.location.replace('/main/scan')
    }
    if (lastpath === 'docs') {
        window.open('https://docs.its-ai.org')
    }
    if (!ready && !tokenReady) {
        return <CenterContainer><Puff width="200" color="grey"/></CenterContainer>
    }
    if (!tokenReady) {
        return <>Loading...</>
    }
    return <>
        {
            // needPolicy && 
            //     <PolicyModal open={Boolean(user && !user.policy_accepted)} onOk={(name: string) => {
            //         acceptPolicy().then(() => {
            //             setName(name);
            //             setNeedPolicy(false);
            //         });
            //     }} onCancel={() => {}}/>
        }
        <Sidebar/>
        {
            lastpath === 'scan' ?
                <Layout/> :
                lastpath === 'statistics' ?
                    <APIUsage/> :
                    lastpath === 'subscription' ?
                        <PremiumPage/> :
                        lastpath == 'britishizer' ?
                            <Britishizer/> :
                            lastpath === 'history' ?
                                <HistoryPage/> :
                                <APITable/>
        }
    </>
}