import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { IUserSettings } from '../types';
import { acceptPolicyRequest, completeOnboardingRequest, enrollUserRequest, getMe, getToken, sendEventRequest, setNameAndRoleRequest, signUp } from '../requests';

interface UserContextType {
    user: IUserSettings | null;
    isLoading: boolean;
    error: string | null;
    login: (privyId: string, email: string, source: string) => Promise<void>;
    refreshLimits: () => Promise<void>;
    logout: () => void;
    setNameAndRole: (name: string, role: string) => Promise<void>;
    acceptPolicy: () => Promise<void>;
    completeOnboarding: () => Promise<void>;
    enrollUser: () => Promise<void>;
}

const UserContext = createContext<UserContextType | undefined>(undefined);

export const UserProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [user, setUser] = useState<IUserSettings | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);

    const refreshLimits = async () => {
        try {
            const userData = await getMe();
            if (typeof userData === 'string') {
                setError(userData);
                setUser(null);
            } else {
                setUser(userData);
                setError(null);
            }
        } catch (err) {
            setError('Failed to refresh user limits');
            setUser(null);
        }
    };

    const login = async (privyId: string, email: string, source: string) => {
        try {
            setIsLoading(true);
            const response = await signUp(privyId, email, source);
            if (response.token) {
                localStorage.setItem('token', response.token);
                await refreshLimits();
            } else {
                setError('Failed to get token');
            }
        } catch (err) {
            setError('Failed to login');
        } finally {
            setIsLoading(false);
        }
    };

    const logout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('id');
        setUser(null);
    };

    const setNameAndRole = async (name: string, role: string) => {
        try {
            await setNameAndRoleRequest(name, role);
            await refreshLimits();
        } catch (err) {
            setError('Failed to set name');
        }
    };

    const acceptPolicy = async () => {
        try {
            await acceptPolicyRequest();
            await refreshLimits();
        } catch (err) {
            setError('Failed to accept policy');
        }
    };

    const completeOnboarding = async () => {
        try {
            await completeOnboardingRequest();
            await refreshLimits();
        } catch (err) {
            setError('Failed to complete onboarding');
        }
    };

    const enrollUser = async () => {
        try {  
            await enrollUserRequest();
            await refreshLimits();
        } catch (err) {
            setError('Failed to enroll user');
        }
    };
    
    useEffect(() => {
        const initializeUser = async () => {
            if (localStorage.getItem('token')) {
                await refreshLimits();
            }
            setIsLoading(false);
        };

        initializeUser();
    }, []);

    const value = {
        user,
        isLoading,
        error,
        login,
        refreshLimits,
        logout,
        setNameAndRole,
        acceptPolicy,
        completeOnboarding,
        enrollUser
    };

    return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export const useMe = () => {
    const context = useContext(UserContext);
    if (context === undefined) {
        throw new Error('useMe must be used within a UserProvider');
    }
    return context;
};

export default useMe;
