import React, { useState } from 'react';
import { Modal } from 'antd';
import styled from 'styled-components';

// Import icons
import { ReactComponent as DoubleUpIcon } from './icons/double-up.svg';
import { ReactComponent as LinkedInIcon } from './icons/linkedin.svg';
import { ReactComponent as TwitterIcon } from './icons/twitter.svg';
import { ReactComponent as FacebookIcon } from './icons/facebook.svg';
import { Button, Input, SecondaryButton } from 'src/pages/application/Login';
import { useSocialMediaForm } from 'src/hooks/useSocialMediaForm';
import { useMe } from 'src/hooks/useMe';
interface ShareModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const StyledModal = styled(Modal)`
  .ant-modal-content {
    padding: 48px 32px;
    width: 446px;
    border-radius: 8px;
    border: 1px solid #EDF1F9;
  }

  .ant-modal-close {
    top: 10px;
    right: 10px;
    color: #CACED9;
    
    &:hover {
      color: #5C5E64;
    }
  }

  .ant-modal-body {
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding: 0;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const HeaderIcon = styled.div`
  background: #7C93F7;
  border-radius: 16px;
  width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  
  svg {
    width: 24px;
    height: 24px;
    path {
      stroke: #F7F9FB;
    }
  }
`;

const HeaderTitle = styled.h2`
  font-family: 'Satoshi', sans-serif;
  font-weight: 700;
  font-size: 32px;
  line-height: 1.35;
  color: #080C1E;
  margin: 0;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  width: 100%;
`;

const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  width: 100%;
  text-align: center;
`;

const Message = styled.p`
  font-family: 'Satoshi-Medium', sans-serif!important;
  font-weight: 500;
  font-size: 18px;
  line-height: 1.35;
  color: #5C5E64;
  margin: 0;
  white-space: pre-line;
  text-align: left;
`;

const SocialContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;

const SocialLabel = styled.p`
  font-family: 'Satoshi-Medium', sans-serif!important;
  font-weight: 500;
  font-size: 18px;
  line-height: 1.35;
  color: #5C5E64;
  margin: 0;
`;

const SocialButtons = styled.div`
  display: flex;
  gap: 16px;
`;

const SocialButton = styled.button<{ active?: boolean }>`
  background: ${props => props.active ? '#EDF0FF' : '#F7F9FB'};
  border: 1px solid ${props => props.active ? '#6B84F0' : '#EDF1F9'};
  border-radius: 12px;
  padding: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  
  svg {
    width: 24px;
    height: 24px;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  width: 100%;
`;

const SecondaryButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 24px;
`;


const Link = styled.div`
  color: '#080C1E';
  font-size: 16px;
  font-family: 'Satoshi-Medium' !important;
  font-weight: 500;
  cursor: pointer;
`

export const ShareModal: React.FC<ShareModalProps> = ({
  isOpen,
  onClose
}) => {
  const { user } = useMe();
  const { onShare, onCheckSharing, dontShowAgain } = useSocialMediaForm();
  const [activePlatform, setActivePlatform] = useState<'linkedin' | 'twitter' | 'facebook' | null>(null);
  const [profileInfo, setProfileInfo] = useState('');
  const [isShared, setIsShared] = useState(false);

  const handleShareClick = (platform: 'linkedin' | 'twitter' | 'facebook') => {
    setActivePlatform(platform);
    
    const text = encodeURIComponent(`I've already detected ${user?.used_words_simple_scan} words with It's AI! It's AI is a powerfull tool for identifying ai-generated texts and is the most accurate ai-detector according to RAID benchmark (ACL paper 2024). With It's AI you can also see which certain parts of the text looks ai-generated and work on them to make your texts more natural and undetectable. Try it yourself: its-ai.org #ItsAI #ai-detector #bestAIdetector`);
    let url = '';
    
    switch(platform) {
      case 'linkedin':
        url = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent("https://its-ai.org")}&summary=${text}`;
        break;
      case 'twitter':
        url = `https://twitter.com/intent/tweet?text=${text}`;
        break;
      case 'facebook':
        url = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent("https://its-ai.org")}&quote=${text}`;
        break;
    }
    
    const windowFeatures = "width=600,height=600,left=100,top=100,resizable=yes,scrollbars=yes";
    window.open(url, `${platform}Share`, windowFeatures);
    onShare(platform);
  };

  const handleConfirmSharing = () => {
    if (activePlatform && profileInfo) {
      setIsShared(true);
    }
  };

  const getPlatformName = () => {
    switch(activePlatform) {
      case 'linkedin':
        return 'LinkedIn';
      case 'twitter':
        return 'Twitter';
      case 'facebook':
        return 'Facebook';
      default:
        return '';
    }
  };

  return (
    <StyledModal
      open={isOpen}
      onCancel={onClose}
      footer={null}
      width={446}
      centered
      maskStyle={{ background: 'rgba(0, 0, 0, 0.25)' }}
    >
      <Header>
        <HeaderIcon>
          <DoubleUpIcon />
        </HeaderIcon>
        <HeaderTitle>Get free Premium</HeaderTitle>
      </Header>
      
      <Content>
        <MessageContainer>
          <Message>
            {isShared 
              ? "Thanks for sharing. We'll check your profile within an hour. \n\nPlease, make sure your profile is public. Premium will be connected today!"
              : <>You've detected over {Math.floor(user?.used_words_simple_scan! / 5000) * 5000} words!<br />Tell about us in your social media and get free <b>Premium for 1 month</b>.</>
            }
          </Message>
          
          {!isShared && (
            <>
              <SocialContainer>
                <SocialLabel>Share on any platform</SocialLabel>
                <SocialButtons>
                  <SocialButton 
                    active={activePlatform === 'linkedin'} 
                    onClick={() => handleShareClick('linkedin')}
                  >
                    <LinkedInIcon />
                  </SocialButton>
                  <SocialButton 
                    active={activePlatform === 'twitter'} 
                    onClick={() => handleShareClick('twitter')}
                  >
                    <TwitterIcon />
                  </SocialButton>
                  <SocialButton 
                    active={activePlatform === 'facebook'} 
                    onClick={() => handleShareClick('facebook')}
                  >
                    <FacebookIcon />
                  </SocialButton>
                </SocialButtons>
              </SocialContainer>
              
              {activePlatform && (
                <div style={{ width: '100%', marginTop: '16px' }}>
                  <Input
                    type="text"
                    placeholder={`Your ${getPlatformName()} profile info`}
                    value={profileInfo}
                    onChange={(e) => setProfileInfo(e.target.value)}
                    style={{margin: 0}}
                  />
                </div>
              )}
            </>
          )}
        </MessageContainer>
        
        <ButtonsContainer>
          {isShared ? (
            <SecondaryButton onClick={() => {
                onCheckSharing(profileInfo);
                onClose();
            }}>
              Ok
            </SecondaryButton>
          ) : (
            <Button onClick={handleConfirmSharing} disabled={!activePlatform || !profileInfo}>
              Confirm Sharing
            </Button>
          )}
          
          {!isShared && (
            <SecondaryButtonsContainer>
              <Link onClick={dontShowAgain}>
                Don't show this again
              </Link>
              <Link onClick={onClose}>
                Remind me later
              </Link>
            </SecondaryButtonsContainer>
          )}
        </ButtonsContainer>
      </Content>
    </StyledModal>
  );
};