import { Cross } from '../icons';
import './style.css';
import react from 'react';
import { useOnboarding } from '../../hooks/useOnboarding';
import Tippy from '@tippyjs/react';


export const TooltipContent: react.FC = () => {
  const data = useOnboarding();
  return <div>
    <div className="tippy-title">
      <span>{data.getCurrentStepInfo()?.title}</span>
      <span style={{cursor: 'pointer'}} onClick={() => data.skipOnboarding()}><Cross color={'white'}/></span>
    </div>
    <span className='tippy-description'>{data.getCurrentStepInfo()?.description}</span>
    <div className="tippy-footer">
      <span>{data.currentStep} of {data.getTotalSteps()}</span>
      <span className='tippy-btn' onClick={() => data.incrementStep()}>Next</span>
    </div>
  </div>;
};

interface TooltipProps {
  id: number;
  children: React.ReactNode;
}

export const Tooltip: React.FC<TooltipProps> = ({ id, children }) => {
  const data = useOnboarding();
  
  return (
    <Tippy 
      content={<TooltipContent />}
      visible={data.currentStep === id}
      className='tippy-container'
      placement={data.getCurrentStepInfo()?.arrowPlacement}
      arrow={true}
      interactive={true}
      
    >
      <div>
        {children}
      </div>
    </Tippy>
  );
};