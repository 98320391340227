import { useState, useEffect } from 'react';
import { useMe } from '../../hooks/useMe';
import { useEnrolling } from '../../hooks/useEnrolling';
import { updateText, sendFileRequest, sendRequest, sendSubscribeRequest } from '../../requests';
import { MIN_LENGTH } from '../../config';
import { logEvent, checkIsEnglish } from '../../utils';
import { FreePremiumForm } from './FreePremiumModal';
import { IncreaseLimitsForm } from './IncreaseLimitsModal';
import { ProgressBar } from '../progressBar';
import { Tooltip } from '../tooltip';
import { QuestionareeForFreePremium } from './QuestionareeForFreePremium';
import { PremiumActivatedModal } from '../PremiumActivatedModal';
import { Button } from 'src/pages/application/Login';

interface ITextareaControll {
  text: string;
  setLoading: (status: boolean) => void;
  setPercents: (value: number) => void;
  setError: (value?: string) => void;
  file: any;
  setSegments: (value: any) => void;
  setText: (value: string) => void;
  wordsModalOpened: boolean;
  setWordsModalOpened: (value: boolean) => void;
  setId: (value: number) => void;
  id: number;
}

export const TextareaControll: React.FC<ITextareaControll> = (props) => {
  const {
      text,
      setText,
      setLoading,
      setPercents,
      setError,
      file,
      setSegments,
      wordsModalOpened,
      setWordsModalOpened,
  } = props;
  const [prevText, setPrevText] = useState('');

  const [freePremiumModalOpened, setFreePremiumModalOpened] = useState(false);
  const [questionnaireModalOpened, setQuestionnaireModalOpened] = useState(false);
  const [premiumActivatedModalOpened, setPremiumActivatedModalOpened] = useState(false);
  const [updated, setUpdated] = useState(false);
  const { refreshLimits, user } = useMe();
  const { incrementEnrollCount, shouldShowEnrollModal } = useEnrolling();
  const {enroll} = useEnrolling();

  useEffect(() => {
      if (Math.abs(text.length - prevText.length) === 1) {
          setUpdated(true);
      }
      if (text.length === 0) {
          setUpdated(false);
      }
      setPrevText(text);
  }, [text]);
  
  useEffect(() => {
      if (updated && props.id !== -1) {
          updateText(props.id.toString(), text);
          setUpdated(false);
      }
  }, [updated]);

  const sendDataToBackend = (deep: boolean) => {
      if (text.length < MIN_LENGTH && !file) {
          setError(`Try typing more text (>${MIN_LENGTH} characters) so we can give you more accurate results`);
          return;
      }
      if (!checkIsEnglish(text) && !file) {
          setError('Only English is supported');
          return;
      }
      logEvent(`scan_${deep ? 'deep' : 'simple'}_scan`);
      if (shouldShowEnrollModal) {
          setFreePremiumModalOpened(true);
      }
      incrementEnrollCount();
      var parsedText = text;
      parsedText = parsedText.replace(/ [\n\r]\d+\.?\d*\s*%\s*(of)?\s*AI[\n\r]/gi, '');
      setText(parsedText);
      setLoading(true);
      setPercents(-1);
      setError(undefined);

      if (file && text.length < MIN_LENGTH) {
          sendFileRequest(file, deep).then((e) => {
              refreshLimits();
              props.setId(e.id);
              if (Number.isNaN(Number.parseFloat(e.answer))) {
                  console.log(e.error);
                  setError(e.error);
                  setLoading(false);
                  return;
              }
              else {
                setText(e.text);
              }
              setPercents(Number.parseFloat(e.answer) * 100);
              if (deep) {
                setSegments(e.segments);
              } else {
                setSegments('');
              }
              setLoading(false);
          })
      } else {
          sendRequest(parsedText, deep, "app").then((e) => {
              refreshLimits();
              setLoading(false);
              if (e.answer != null) {
                  props.setId(e.id);
                  if (updated) {
                      updateText(e.id.toString(), text);
                      setUpdated(false);
                  }
                  setPercents((e.answer as any) * 100);
                  if (deep) {
                      setSegments(e.segments);
                  } else {
                      setSegments('');
                  }
              } else {
                  setError(e.error);
              }
          })
      }
  }


  return <div className="textarea__controll">
      <PremiumActivatedModal
          open={premiumActivatedModalOpened}
          onExplore={() => {setPremiumActivatedModalOpened(false)}}
          onClose={() => {setPremiumActivatedModalOpened(false)}}
      />
      <QuestionareeForFreePremium
          open={questionnaireModalOpened}
          onOk={() => {
            setQuestionnaireModalOpened(false);
            enroll().then(() => {
              setPremiumActivatedModalOpened(true);
            });
        }}
          onCancel={() => setQuestionnaireModalOpened(false)}
      />
      <FreePremiumForm
          open={freePremiumModalOpened}
          onOk={() => {
            setFreePremiumModalOpened(false);
            setQuestionnaireModalOpened(true);
          }}
          onCancel={() => setFreePremiumModalOpened(false)}
      />
      <IncreaseLimitsForm
          open={wordsModalOpened}
          onOk={() => setWordsModalOpened(false)}
          onCancel={() => setWordsModalOpened(false)}
      />
      <div className="words__count">
          <div className="words">
              {text.length} / {user?.words_per_generation_limit} characters
          </div>
          <ProgressBar
              progress={text.length / (user?.words_per_generation_limit || 5000) * 100}
          />
      </div>
      <div className="fd__btns">
      <Button className='primary fd__button' onClick={() => sendDataToBackend(false)}>
                  Simple scan
              </Button>
          <Tooltip id={1}>
              <Button className='primary fd__button' onClick={() => sendDataToBackend(true)}>
                  Deep scan
              </Button>
          </Tooltip>
      </div>

      </div>
}