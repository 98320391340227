import { useRef, useEffect } from 'react';
import { useMe } from '../../hooks/useMe';
import { getErrorBorderColor } from '../../utils';
import { DisplayError } from './DisplayError';
import { BuildSegments, buildSentenceTooltipStyle } from '../../segments/segments';
import { renderToString } from 'react-dom/server';

interface ITextarea {
  error?: string;
  text: string;
  setText: (text: string) => void;
  setFile: (file?: any) => void;
  segments: string;
  setWordsModalOpened: (value: boolean) => void;
}


export const Textarea: React.FC<ITextarea> = (props) => {
  const {error, text, setText, setFile, segments, setWordsModalOpened} = props;
  const divRef = useRef<HTMLDivElement>(null);
  const { user } = useMe();
  useEffect(() => {
      document.querySelector('#textarea')!.addEventListener("scroll", () => {
          try {
              document.querySelectorAll('.tooltip__parent').forEach((e) => {
                  (e as any).querySelector('div').querySelector('div').style = buildSentenceTooltipStyle(false);
                  (e as any).querySelector('div').style = ''
                  document.querySelectorAll('.tooltip__parent').forEach((ee) => {
                      (ee as any).style = ''
                  });
              })
          } catch {
          }
      })
      document.querySelectorAll('.tooltip__parent').forEach((e) => {
          (e as any).onmouseover = () => {
              if (window.getSelection()?.toString().length !== 0) return;
              try {
                  (e as any).querySelector('div').querySelector('div').style = buildSentenceTooltipStyle(true);
                  (e as any).querySelector('div').style = `position: absolute; transform: translateY(-${(e as any).parentElement.scrollTop}px)`;
                  document.querySelectorAll('.tooltip__parent').forEach((ee) => {
                      (ee as any).style = 'background-color: white!important;'
                  });
                  (e as any).style = ''
              } catch {
              }
          }
          (e as any).onmouseleave = () => {
              try {
                  (e as any).querySelector('div').querySelector('div').style = buildSentenceTooltipStyle(false);
                  (e as any).querySelector('div').style = ''
                  document.querySelectorAll('.tooltip__parent').forEach((ee) => {
                      (ee as any).style = ''
                  });
              } catch {
              }
          }
      })

      const handleKeyDown = (event: KeyboardEvent) => {
          if (event.ctrlKey && event.key === 'z') {
              event.preventDefault();
          }
      };
      if (divRef.current) {
          divRef.current.addEventListener('keydown', handleKeyDown);
      }
      return () => {
          if (divRef.current) {
              divRef.current.removeEventListener('keydown', handleKeyDown);
          }
      };
  }, [segments, divRef]);

  return <div
      className={"textarea " + (error ? 'textarea__alert-parent' : '')}
      style={error ? {
          borderColor: getErrorBorderColor(error!)
      } : {}}
  >
      {
          error ?
              <DisplayError error={error} isPremium={user?.subscription !== 'Free'} /> : <></>
      }
      {
          segments ?
              <div
                  contentEditable={true}
                  id="textarea"
                  style={{height: 'calc(100% - 60px)', overflow: 'scroll'}}
                  className={
                      error?.length ?
                          'textarea__alert-container' : ''
                  }
                  ref={divRef}
                  onInput={(e: any) => {
                      if (e.ctrlKey && e.key === 'z') {
                          return;
                      }
                      var currentText = e.currentTarget.innerText.slice(0, user?.words_per_generation_limit);
                      if (currentText === '\n') {
                          setText('');
                      } else {
                          setText(currentText);
                      }
                      setFile(undefined);
                  }}
                  dangerouslySetInnerHTML={{__html: renderToString(<BuildSegments data={segments as any}/>)}}
              ></div> : <textarea onChange={(e) => {
                  if (user?.words_per_generation_limit == 5000 && e.target.value.length > 5000) {
                      setWordsModalOpened(true);
                  }
                  setText(e.target.value.slice(0, user?.words_per_generation_limit));
                  setFile(undefined);
              }}
                  value={text}
                  id='textarea'
                  placeholder='Enter text or upload document to run scan (currently only English supported)'
              ></textarea>
      }

  </div>
}