import {createBrowserRouter, Navigate} from 'react-router-dom'
import { Main } from './pages/application/main'
import { ExtensionLogin } from './pages/application/extensionLogin'
import Login from './pages/application/Login'


export const appRouter = createBrowserRouter([
    {
        path: '/',
        element: <Navigate to="/main/scan" replace />
    },
    {
        path: '/login',
        element: <Login />
    },
    {
        path: '/main',
        element: <Main />
    },
    {
        path: '/main/scan',
        element: <Main />
    },
    {
        path: '/main/britishizer',
        element: <Main />
    },
    {
        path: '/main/api',
        element: <Main />
    },
    {
        path: '/main/subscription',
        element: <Main />
    },
    {
        path: '/main/statistics',
        element: <Main />
    },
    {
        path: '/main/api/table',
        element: <Main />
    },
    {
        path: '/main/api/docs',
        element: <Main />
    },
    {
        path: '/main/history',
        element: <Main />
    },
    {
        path: '/extension',
        element: <ExtensionLogin />
    }
])

export default appRouter;