import axios from 'axios'
import {BACKEND_URL} from "./config";
import {mapSegments} from './segments/segments';
import {IUserSettings} from './types';
import {getClientIdFromCookie} from "./utils";

export const bareRequest = async (text: string) => {
    const data = await axios.get(BACKEND_URL + 'bare-request?source=app&text=' + text.replaceAll('\n', '%0A'));
    return data.data.answer;
}

export const signUp = async (privyId: string, email: string, source: string) => {
    const data = await axios.post(BACKEND_URL + 'users/signup/', {
        username: email,
        password: privyId,
        source: source,
        client_id: getClientIdFromCookie()
    });
    return {token: data.data.token};
}

export const sendRequest = async (text: string, deepScan: boolean, source: string = "unknown") => {
    try {
        const data = await axios.post(
            BACKEND_URL + 'text',
            {'text': text, "deep_scan": deepScan, "source": source},
            {
                headers: {
                    'Authorization': 'Token ' + localStorage.getItem('token')
                }
            }
        );
        var segments: number[] = data.data.segmentation_tokens;
        return {
            "answer": data.data.answer.toFixed(2),
            "error": null,
            "segments": deepScan ? mapSegments(text, segments) : null,
            "id": data.data.id
        };
    } catch (e) {
        return {
            "answer": null,
            "error": (e as any).response.data.error,
            "segments": null
        }
    }

}

const isAuthenticated = () => {
    return localStorage.getItem('token') !== null;
}

export const utmRequest = async (options: object) => {
    if (isAuthenticated()) {
        return await sendEventRequest("utm-app", options)
    } else {
        return await axios.post(BACKEND_URL + 'utm', {event: "utm-app", options: options});
    }
}

const request = async (endpoint: string, data: object) => {
    return await axios.post(
        BACKEND_URL + endpoint,
        data,
        {
            headers: {
                'Authorization': 'Token ' + localStorage.getItem('token')
            }
        }
    );
}

export const britishizeRequest = async (text: string) => {
    try {
        const response = await request("britishize", {"text": text, "source": "app"});
        return {
            "error": null,
            "text": response.data.text,
        };
    } catch (e) {
        return {
            "error": (e as any).response?.data?.error || "Server error",
            "text": ""
        }
    }

}

export const sendFileRequest = async (file: File, deepScan: boolean) => {
    var bodyFormData = new FormData();
    bodyFormData.append('file', file);
    if (deepScan) {
        bodyFormData.append('deep_scan', 'true');
    }
    try {
        const data = await axios.post(
            BACKEND_URL + 'text',
            bodyFormData,
            {
                headers: {
                    'Authorization': 'Token ' + localStorage.getItem('token'),
                    "Content-Type": "multipart/form-data"
                }
            }
        );
        var segments: number[] = data.data.segmentation_tokens;
        return {
            "answer": data.data.answer.toFixed(2),
            "error": null,
            "segments": deepScan ? mapSegments(data.data.text, segments) : null,
            "id": data.data.id,
            "text": data.data.text
        };
    } catch (e) {
        return {
            "answer": null,
            "error": (e as any).response.data.error,
            "segments": null
        }
    }
}

export const createAPIKey = async (name: string) => {
    if (!name.length) name = '-';
    var data = await axios.post(
        BACKEND_URL + 'key', {name: name},
        {
            headers: {
                'Authorization': 'Token ' + localStorage.getItem('token')
            }
        }
    );
    return data.data;
}

export const getApiKeys = async () => {
    var data = await axios.get(
        BACKEND_URL + 'key',
        {
            headers: {
                'Authorization': 'Token ' + localStorage.getItem('token')
            }
        }
    );
    return data.data;
}

export const deleteAPIKey = async (key: string) => {
    var data = await axios.delete(
        BACKEND_URL + 'key/' + key,
        {
            headers: {
                'Authorization': 'Token ' + localStorage.getItem('token')
            }
        }
    );
    return data.data;
}

export const getToken = async (privyId: string, email: string, source: string) => {
    try {
        const response = await axios.post(BACKEND_URL + 'auth-token/', {
            username: email,
            password: privyId,
            client_id: getClientIdFromCookie()
        }, {headers: {"Content-Type": 'application/json'}});
        return response.data;
    } catch {
        return {token: await signUp(privyId, email, source)};
    }
}

export const updateAPIKey = async (key: string, title: string) => {
    var data = await axios.patch(
        BACKEND_URL + 'key/' + key,
        {
            name: title
        },
        {
            headers: {
                'Authorization': 'Token ' + localStorage.getItem('token')
            }
        }
    );
    return data.data;
}

export const getMe = async (): Promise<IUserSettings | string> => {
    const token = localStorage.getItem('token');
    if (!token) return 'error';
    try {
        var data = await axios.get(
            BACKEND_URL + 'users/me/',
            {
                headers: {
                    'Authorization': 'Token ' + token
                }
            }
        );
        console.log(localStorage.getItem('token'));
        localStorage.setItem('id', data.data.id);
        return data.data;
    } catch (e) {
        console.log(e);
        return 'error'
    }

}

export const getAnalytics = async (trashold: number) => {
    var data = await axios.post(
        BACKEND_URL + 'analitycs',
        {
            trashold
        },
        {
            headers: {
                'Authorization': 'Token ' + localStorage.getItem('token')
            }
        }
    );
    return data.data;
}

export const sendSubscribeRequest = async (is_free: boolean) => {
    var data = await axios.post(
        BACKEND_URL + 'subscribe-request',
        {
            is_free
        },
        {
            headers: {
                'Authorization': 'Token ' + localStorage.getItem('token')
            }
        }
    )
    return data.data;
}

export const sendEventRequest = async (event: string, options = {}) => {
    const token = localStorage.getItem('token');
    if (!token) return 'error';
    var data = await axios.post(
        BACKEND_URL + 'events',
        {
            event: event,
            options: options
        },
        {
            headers: {
                'Authorization': 'Token ' + token
            }
        }
    )
    return data.data;
}

export const mapAnonUser = async (anonUsername: string) => {
    var data = await axios.post(
        BACKEND_URL + 'users/map_anon_user/',
        {
            anon_username: anonUsername
        },
        {
            headers: {
                'Authorization': 'Token ' + localStorage.getItem('token')
            }
        }
    );
    return data.data;
}

export const getHistoryRequest = async (page: number, pageSize: number) => {
    var data = await axios.get(BACKEND_URL + 'text?page=' + page + "&page_size=" + pageSize, {
        headers: {
            'Authorization': 'Token ' + localStorage.getItem('token')
        }
    });
    return data.data;
}

export const getFeedback = async (id: string, feedback: 'positive' | 'negative') => {
    var data = await axios.post(
        BACKEND_URL + `feedback/${id}`,
        {
            feedback: feedback
        },
        {
            headers: {
                'Authorization': 'Token ' + localStorage.getItem('token')
            }
        }
    );
    return data.data;
}

export const getUserByHeader = async () => {
    const token = localStorage.getItem('token');
    if (!token) return null;
    const data = await axios.get(
        BACKEND_URL + 'users/get_user_by_header/',
        {
            headers: {
                'Authorization': 'Token ' + token
            }
        }
    );
    return data.data;
}

export const updateText = async (id: string, text: string) => {
    const data = await axios.post(
        BACKEND_URL + `text/${id}/update/`,
        {
            text: text
        },
        {
            headers: {
                'Authorization': 'Token ' + localStorage.getItem('token')
            }
        }
    );
    return data.data;
}

export const acceptPolicyRequest = async () => {
    const data = await axios.post(
        BACKEND_URL + 'users/accept_policy/',
        {},
        {
            headers: {
                'Authorization': 'Token ' + localStorage.getItem('token')
            }
        }
    );
    return data.data;
}

export const setNameAndRoleRequest = async (name: string, role: string) => {
    const data = await axios.post(
        BACKEND_URL + 'users/set_name_and_role/',
        {
            name: name,
            role: role
        },
        {
            headers: {
                'Authorization': 'Token ' + localStorage.getItem('token')
            }
        }
    );
    return data.data;
}

export const sendQuestionnaireRequest = async (
    workPlace: string,
    companyName: string,
    jobTitle: string,
    jobPosition: string,
    aiDetectorPurpose: string,
    currentSolution: string,
    discoverySource: string,
    otherTools: string,
    importantFeatures: string
) => {
    const data = await axios.post(
        BACKEND_URL + 'questionnaire/',
        {
            work_place: workPlace,
            company_name: companyName,
            job_title: jobTitle,
            job_position: jobPosition,
            ai_detector_purpose: aiDetectorPurpose,
            current_solution: currentSolution,
            discovery_source: discoverySource,
            other_tools: otherTools,
            important_features: importantFeatures
        },
        {
            headers: {
                'Authorization': 'Token ' + localStorage.getItem('token')
            }
        }
    );
    return data.data;
}

export const completeOnboardingRequest = async () => {
    const data = await axios.post(
        BACKEND_URL + 'users/complete_onboarding/',
        {},
        {
            headers: {
                'Authorization': 'Token ' + localStorage.getItem('token')
            }
        }
    );
    return data.data;
}

export const enrollUserRequest = async () => {
    const data = await axios.post(
        BACKEND_URL + 'users/enroll_user/',
        {},
        {
            headers: {
                'Authorization': 'Token ' + localStorage.getItem('token')
            }
        }
    );
    return data.data;
}

export const socialMediaSubscribeRequest = async (source: string, linkUrl: string) => {
    const data = await axios.post(
        BACKEND_URL + 'social-media-subscribe/',
        {
            source: source,
            link_url: linkUrl
        },
        {
            headers: {
                'Authorization': 'Token ' + localStorage.getItem('token')
            }
        }
    );
    return data.data;
}
