import React, { useState, Children, cloneElement, isValidElement } from 'react';
import styled from 'styled-components';
import { Radio } from './index';

interface RadioGroupProps {
  selected?: string;
  onChange?: (selected: string) => void;
  size?: 'small' | 'medium' | 'large';
  children: React.ReactNode;
}

const RadioGroupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const RadioGroup: React.FC<RadioGroupProps> = ({
  selected,
  onChange,
  size = 'medium',
  children
}) => {
  const [selectedInternal, setSelectedInternal] = useState<string>(selected || '');

  const handleRadioClick = (value: string) => {
    const newSelected = value;
    setSelectedInternal(newSelected);
    onChange?.(newSelected);
  };

  const processChild = (child: React.ReactNode): React.ReactNode => {
    if (!isValidElement(child)) {
      return child;
    }

    if (child.type === Radio) {
      const value = child.props.value || child.props.label || '';
      const isSelected = selected ? selected === value : selectedInternal === value;

      return cloneElement(child, {
        ...child.props,
        name: 'radio-group',
        size: child.props.size || size,
        active: isSelected,
        onClick: (event: React.MouseEvent<HTMLInputElement>) => {
          handleRadioClick(value);
          child.props.onClick?.(event);
        }
      });
    }

    if (child.props.children) {
      return cloneElement(child, {
        ...child.props,
        children: Children.map(child.props.children, processChild)
      });
    }

    return child;
  };

  const renderChildren = () => {
    return Children.map(children, processChild);
  };

  return (
    <RadioGroupWrapper>
      {renderChildren()}
    </RadioGroupWrapper>
  );
};

export default RadioGroup; 